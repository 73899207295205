import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseApi from "../Http/baseApi";
import messageSlice from "./messageSlice";
import { toast } from "react-toastify";

// const user = JSON.parse(localStorage.getItem("userData"));

export const postLoginAsync = createAsyncThunk(
  "auth/postLoginAsync",
  async (data, thunkAPI) => {
    const { payload } = data;

    try {
      const response = await baseApi.post("auth/signin", payload);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(messageSlice.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  localStorage.removeItem("userData");
});

export const getPatientsAsync = createAsyncThunk(
  "patients/getPatientsAsync",
  async (payload) => {
    const response = await baseApi.get(
      `patients?user_type=` + payload.userType + `&user_id=` + payload.userId
    );
    return response.data;
  }
);

export const getPatientsDraftsAsync = createAsyncThunk(
  "patients/getPatientsDraftsAsync",
  async (payload) => {
    const response = await baseApi.get(
      `drafts?user_type=` + payload.userType + `&user_id=` + payload.userId
    );
    return response.data;
  }
);
export const getAllCasesAsync = createAsyncThunk(
  "patients/getAllCasesAsync",
  async (payload) => {
    const response = await baseApi.get(`allcases`);
    return response.data;
  }
);
export const getPatientsByIdAsync = createAsyncThunk(
  "patients/getPatientsByIdAsync",
  async (payload) => {
    const response = await baseApi.get(`patients/${payload.id}`);
    return response.data;
  }
);

export const getFamilyMembersAsync = createAsyncThunk(
  "patients/getFamilyMembersAsync",
  async (payload) => {
    const response = await baseApi.get(
      `family-members?user_id=` + payload.userId
    );
    return response.data;
  }
);

export const addPatientAsync = createAsyncThunk(
  "patients/addPatientsAsync",
  async (payload) => {
    // console.log(payload);
    let postData = { ...payload.basicInfo };
    postData.draft = payload.clickStatus; 
    try {
      const response = await baseApi.post("patients", postData);
      toast.success("Data Saved Successful!");
    } catch (err) {
      console.log(err.response);
      if (err.response.status === 409) {
        toast.error(err.response.data.message);
      }
    }
  }
);

export const addFamilyAsync = createAsyncThunk(
  "patients/addFamilyAsync",
  async (payload) => {
    // console.log(payload);
    let postData = { ...payload.basicInfo };
    postData.draft = payload.clickStatus; 
    try {
      const response = await baseApi.post("add_family_member", postData);
      toast.success("Data Saved Successful!");
    } catch (err) {
      console.log(err.response);
      if (err.response.status === 409) {
        toast.error(err.response.data.message);
      }
    }
  }
);
export const updatePatientAsync = createAsyncThunk(
  "patients/updatePatientAsync",
  async (payload) => {
    const response = await baseApi.put(`patients/${payload.id}`, payload);
    if(response.status=="200"){
      toast.success("Updated Successfully!");  
      return payload;
    }
  }
); 

export const deletePatientAsync = createAsyncThunk(
  "patients/deletePatientAsync",
  async (payload) => {
    await baseApi.delete(`patients/${payload.id}`);

    return payload;
  }
);

const initialState = {
  patients: [],
  drafts: [],
  patient: [],
  errorMessage: "",
};

const patientSlice = createSlice({
  name: "patients",
  initialState,
  reducers: {},
  extraReducers: {
    [postLoginAsync.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      state.panel = action.payload.panel;
      localStorage.setItem("userData", JSON.stringify(action.payload));
    },

    [postLoginAsync.rejected]: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.panel = null;
    },
    [logout.fulfilled]: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.panel = null;
    },

    [getPatientsAsync.fulfilled]: (state, action) => {
      return { ...state, patients: action.payload };
    },

    [getAllCasesAsync.fulfilled]: (state, action) => {
      return { ...state, patients: action.payload };
    },

    [getPatientsByIdAsync.fulfilled]: (state, action) => {
      console.log("get data by Id");
      return { ...state, patient: action.payload };
    },
    [getPatientsDraftsAsync.fulfilled]: (state, action) => {
      console.log("get Drafts data by Id");
      return { ...state, drafts: action.payload };
    },

    [getPatientsByIdAsync.rejected]: (state) => {
      console.log("Error occured Retrieving Data!");
      return { ...state, errorMessage: "Something went wrong" };
    },

    [getFamilyMembersAsync.fulfilled]: (state, action) => {
      console.log("Get Family Members Data");
      return { ...state, patients: action.payload };
    },

    [addPatientAsync.fulfilled]: (state, action) => {
      state.patients.push(action.payload);
      return { ...state, patients: action.payload };
    },
    [addFamilyAsync.fulfilled]: (state, action) => {
      state.patients.push(action.payload);
      return { ...state, patients: action.payload };
    },

    [updatePatientAsync.fulfilled]: (state, action) => {
      const index = state.patients.findIndex(
        (patient) => patient.id === action.payload.id
      );
      state.patients[index] = action.payload;
      // return {...state, users: action.payload} ;
    },
    [updatePatientAsync.rejected]: (state) => {
      console.log("Error occured");
      return { ...state, errorMessage: "Something went wrong" };
    },
    [deletePatientAsync.fulfilled]: (state, action) => {
      let patients = state.patients.filter(
        (patient) => patient.id !== action.payload.id
      );

      return { ...state, patients: patients };
    },
  },
});

export default patientSlice.reducer;
