import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseApi from "../Http/baseApi";
import { toast } from "react-toastify";
// import { updateUserTypeAsync } from "./masterSlice";
import { Link, useNavigate, Navigate } from "react-router-dom";

export const addBPAsync = createAsyncThunk(
  "bprecord/addBPAsync",
  async (payload) => {
    try {
      const response = await baseApi.post("bp-records", payload);
      toast.success("Record Added Successful!");
      return response.data;
    } catch (err) {
      console.log(err.response);
      if (err.response.status === 409) {
        toast.error(err.response.data.message);
      }
    }
  }
);
export const getBPRecordsByIdAsync = createAsyncThunk(
  "bprecord/getBPRecordsByIdAsync",
  async (payload) => {
    const response = await baseApi.get(`bp-records/${payload.id}`);
    // const response = await baseApi.get(`patients?user_type=`+payload.userType+`&user_id=`+payload.userId);
    // userType: authData.panel, userId: authData.user.id
    return response.data;
  }
);

export const updateBP = createAsyncThunk(
  "bprecord/updateBP",
  async (payload) => {
    console.log("payload id", payload);
    const resp = await baseApi.put(`bp-records/${payload.id}`, payload);
    console.log("response", resp.status);
    if (resp.status === 200) {
      toast.success("Record Updated Successful!");
    }
  }
);

const initialState = {
  bpRecords: [],

  errorMessage: "",
};

const bpSlice = createSlice({
  name: "bprecords",
  initialState,
  reducers: {},
  extraReducers: {
    [addBPAsync.fulfilled]: (state, action) => {
      console.log("===", action);
      // state.bpRecords.push(action.payload);
      // return {...state, bpRecords: action.payload} ;
    },
    [getBPRecordsByIdAsync.fulfilled]: (state, action) => {
      return { ...state, bpRecords: action.payload };
      // return {...state, bpRecords: action.payload} ;
    },

    [updateBP.fulfilled]: (state, action) => {
      return { ...state };
    },

    [updateBP.rejected]: (state) => {
      console.log("Error occured");
      return { ...state, errorMessage: "Something went wrong" };
    },
  },
});

export default bpSlice.reducer;
