import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link,Navigate,useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PageNotFound from "../PageNotFound";

import { getFamilyMembersAsync } from "../Redux/patientSlice";
import UnAuthorised from "../UnAuthorised";

export default function PatientDashboard() {  

	const navigation = useNavigate(); 
	const dispatch = useDispatch();
	const authData = useSelector((state) => state.auth);
	const patients = useSelector((state) => state.patientSlice.patients);
	const male = patients.filter( (record)=> record.gender==="Male")
	const female = patients.filter( (record)=> record.gender==="Female")
  
	useEffect(() => {
	  // console.log("Family Members Components");    
	  dispatch(getFamilyMembersAsync({ userId: authData.user.id }));
	}, [dispatch]);
	console.log("Logged User Details", authData);

  return (
    <>
     
     <div class="content-wrapper">
        <div class="container-full">
        <section class="content">
			<div class="row">
				<div class="col-xl-12 col-12">
					<div class="row"> 
					<div class="col-md-4 col-12">
							<div class="box">
								<div class="box-body">
									<h4>Total Family Members</h4>
									<div class="d-flex justify-content-between align-items-center">
										<h2 class="fs-40 my-0">{patients.length ? patients.length : "0"}</h2>
										 
									</div>
								</div>
                                
							</div>
							 
						</div>

						<div class="col-md-4 col-12">
							<div class="box">
								<div class="box-body">
									<h4>Male</h4>
									<div class="d-flex justify-content-between align-items-center">
										<h2 class="fs-40 my-0">{male.length ? male.length : "0"}</h2>
										<div>
											{/* <span class="badge badge-pill badge-success-light"><i class="fa fa-angle-up me-10"></i> 60%</span> */}
										</div>
									</div>
								</div>
                                
							</div>
							 
						</div>

						<div class="col-md-4 col-12"> 
							<div class="box">
								<div class="box-body">
									<h4>Female</h4>
									<div class="d-flex justify-content-between align-items-center">
										<h2 class="fs-40 my-0">{female.length}</h2>
										<div>
											{/* <span class="badge badge-pill badge-danger-light"><i class="fa fa-angle-down me-10"></i>40%</span> */}
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
					 
				</div>	
				{/* <div class="col-xl-12 col-12">					
					<div class="box b-0 bg-transparent no-shadow">
						<div class="box-body pt-5 pb-0 px-0">
							<div class="d-flex justify-content-between align-items-center">
								<h4 class="mb-0">My Family Members</h4>
								<a href="#" class="">All Members <i class="ms-10 fa fa-angle-right"></i></a>
							</div>							
						</div>
					</div>				
					<div class="box">
						<div class="box-body p-15">
							<FamilyMembers/>
                            
						</div>
					</div>

                   
					 
				</div>  */}
				 
			</div>			
		</section>
        </div>
      </div>
    </>
  )
}
