import { React } from 'react'
export default function Contact() {

   
 
  return (
    <>
      <div className="content-wrapper">
        <div className="container-full">
          <div class="content-header">
            <div class="d-flex align-items-center">
              <div class="me-auto">
                <h4 class="page-title">For any query, pl contact</h4>
              </div>
            </div>
          </div>

          <section class="content">
            <div class="row"> 
              <div class="col-4 col-lg-4 col-xl-4">
                <div class="box box-widget widget-user">
                  <div class="widget-user-header bg-img bbsr-0 bber-0">
                    <h3 class="widget-user-username text-grey text-center"> 
                  <br /> <small> Dr.Manjula Suresh</small> <br />  
                  <small>Sr. Vice President - Medical Services </small> <br />  
                  <small>9845122853</small>
                    </h3> 
                  </div> 
                </div> 
              </div>


              <div class="col-4 col-lg-4 col-xl-4">
                <div class="box box-widget widget-user">
                  <div class="widget-user-header bg-img bbsr-0 bber-0">
                    <h3 class="widget-user-username text-grey text-center"> 
                  <br /> <small> Dr.Krishna Kumar</small> <br />  
                  <small>General Manager - Medical Services</small> <br />  
                  <small>9916043259</small>
                    </h3> 
                  </div> 
                </div> 
              </div>


              <div class="col-4 col-lg-4 col-xl-4">
                <div class="box box-widget widget-user">
                  <div class="widget-user-header bg-img bbsr-0 bber-0">
                    <h3 class="widget-user-username text-grey text-center"> 
                  <br /> <small> Sirish Samak</small> <br />  
                  <small>Sr. Vice President - Sales & Marketing</small> <br />  
                  <small>9624703037</small>
                    </h3> 
                  </div> 
                </div> 
              </div>
              <div class="col-4 col-lg-4 col-xl-4">
                <div class="box box-widget widget-user">
                  <div class="widget-user-header bg-img bbsr-0 bber-0">
                    <h3 class="widget-user-username text-grey text-center"> 
                  <br /> <small> Harish Murthy</small> <br />  
                  <small>Sr. General Manager - Marketing</small> <br />  
                  <small>9611293738</small>
                    </h3> 
                  </div> 
                </div> 
              </div>

            
            </div>
          </section>
        </div>
      </div>
    </>
  )
}
