import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Components/Common/Layout";
import Login from "./Doctor/Login";
import Home from "./Home";
import PageNotFound from "./PageNotFound";
import DoctorDashboard from "./Doctor/DoctorDashboard";
import DoctorRegistration from "./Doctor/DoctorRegistration";
import PatientDashboard from "./Patient/PatientDashboard";
import AddCase from "./Pages/AddCase";
import AdminDashboard from "./Admin/AdminDashboard";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import axios from "axios";
import { useState } from "react";
import ViewCases from "./Pages/ViewCases";
import AddFamily from "./Patient/AddFamily";
import BpMonitoring from "./Patient/BpMonitoring";
import DoctorList from "./Doctor/DoctorList";
import PatientProfile from "./Patient/PatientProfile";
import ForgotPassword from "./Doctor/ForgotPassword";
import DoctorProfile from "./Doctor/DoctorProfile";
import MemberLogin from "./Patient/MemberLogin";
import AddBpRecords from "./Patient/AddBpRecords";
import Doctors from "./Doctor/Doctors";
import FamilyMembers from "./Patient/FamilyMembers";
import Reports from "./Pages/Reports";
import BpReport from "./Pages/BpReport";
import AllCases from "./Reports/AllCases";
import Drafts from "./Patient/Drafts";
import Contact from "./Pages/Contact";
import Instructions from "./Pages/Instructions";
import PrivacyPolicy from "./Pages/PrivacyPolicy";

// axios.defaults.baseURL = "http://localhost:3003/api/";

axios.defaults.baseURL = "https://api.hypertensionfoundationofindia.com/api/";


toast.configure();

function App(props) {
  return (
    <BrowserRouter>
      <Routes>
        {/* <ProtectedRouter/>  */}
        {/* <Route index element={<Home/>} />  */}

        <Route path="/login" element={<Login />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/member-login" element={<MemberLogin />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/doctor-registration" element={<DoctorRegistration />} />

        <Route path="/" element={<Layout />}>
          <Route path="/doctor-dashboard" element={<DoctorDashboard />} />
          <Route path="/patient-dashboard" element={<PatientDashboard />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/add-case" element={<AddCase />} />
          <Route path="/edit-case/:id" element={<AddCase />} />
          <Route path="/view-cases" element={<ViewCases />} />
          <Route path="/draft-cases" element={<Drafts />} />
          <Route path="/add-family-members/:id" element={<AddFamily />} />
          {/* <Route path="/add-family-members/:id" element={<AddFamily />} /> */}
          <Route path="/bp-monitoring" element={<BpMonitoring />} />
          <Route path="/bp-reports" element={<BpReport />} />
          <Route path="/view-doctors" element={<Doctors />} />
          <Route path="/patient-profile/:id" element={<PatientProfile />} />

          <Route path="/doctor-profile/:id" element={<DoctorProfile />} />
          <Route path="/add-bp-records/:id" element={<AddBpRecords />} />
          <Route path="/family-members" element={<FamilyMembers />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/instructions" element={<Instructions />} />
          <Route path="/all-cases" element={<AllCases />} />
        </Route>
        <Route path="*" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
