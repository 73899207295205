import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import UnAuthorised from "../UnAuthorised";
import { useParams } from "react-router-dom";

import { addPatientAsync,addFamilyAsync, getPatientsAsync, getPatientsByIdAsync } from "../Redux/patientSlice";

export default function AddFamily() {
  //   const [searchParams] = useSearchParams();
  // let r_type = searchParams.get("r_type");
  const patient = useSelector((state) => state.patientSlice.patient);
  console.log("Patient Data", patient);

  const textInput = React.useRef();
  const [number, setNumber] = useState("");
  let showStore = false;

  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  console.log("User data", authData.panel);

  const params = useParams();
  console.log("Auth data", authData);
  var doctorId="";
   if(authData.panel==="Doctor"){
    doctorId= authData.user.id;
   }
   if(authData.panel==="Patient"){
    doctorId= patient.user_id;
   } 

  console.log("Auth Data", authData); 
  console.log("Doctor Id", doctorId);  
  console.log("Patient Data", patient);
  console.log("Patient Id", patient.id); 
  console.log("Params Id", params.id); 



  const randomNumber = () => {
    // const val = Math.floor(1000 + Math.random() * 9000);
    // setNumber(val);
  };

  let iniInch = 47;
  let currTime = new Date();
  const clearInput = () => (textInput.current.value = "0");
  const [formRole, setFormRole] = useState("add");
  const stateList = [
    {
      abbreviation: "AN",
      name: "Andaman and Nicobar Islands",
    },
    {
      abbreviation: "AP",
      name: "Andhra Pradesh",
    },
    {
      abbreviation: "AR",
      name: "Arunachal Pradesh",
    },
    {
      abbreviation: "AS",
      name: "Assam",
    },
    {
      abbreviation: "BR",
      name: "Bihar",
    },
    {
      abbreviation: "CG",
      name: "Chandigarh",
    },
    {
      abbreviation: "CH",
      name: "Chhattisgarh",
    },
    {
      abbreviation: "DH",
      name: "Dadra and Nagar Haveli",
    },
    {
      abbreviation: "DD",
      name: "Daman and Diu",
    },
    {
      abbreviation: "DL",
      name: "Delhi",
    },
    {
      abbreviation: "GA",
      name: "Goa",
    },
    {
      abbreviation: "GJ",
      name: "Gujarat",
    },
    {
      abbreviation: "HR",
      name: "Haryana",
    },
    {
      abbreviation: "HP",
      name: "Himachal Pradesh",
    },
    {
      abbreviation: "JK",
      name: "Jammu and Kashmir",
    },
    {
      abbreviation: "JH",
      name: "Jharkhand",
    },
    {
      abbreviation: "KA",
      name: "Karnataka",
    },
    {
      abbreviation: "KL",
      name: "Kerala",
    },
    {
      abbreviation: "LD",
      name: "Lakshadweep",
    },
    {
      abbreviation: "MP",
      name: "Madhya Pradesh",
    },
    {
      abbreviation: "MH",
      name: "Maharashtra",
    },
    {
      abbreviation: "MN",
      name: "Manipur",
    },
    {
      abbreviation: "ML",
      name: "Meghalaya",
    },
    {
      abbreviation: "MZ",
      name: "Mizoram",
    },
    {
      abbreviation: "NL",
      name: "Nagaland",
    },
    {
      abbreviation: "OR",
      name: "Odisha",
    },
    {
      abbreviation: "PY",
      name: "Puducherry",
    },
    {
      abbreviation: "PB",
      name: "Punjab",
    },
    {
      abbreviation: "RJ",
      name: "Rajasthan",
    },
    {
      abbreviation: "SK",
      name: "Sikkim",
    },
    {
      abbreviation: "TN",
      name: "Tamil Nadu",
    },
    {
      abbreviation: "TS",
      name: "Telangana",
    },
    {
      abbreviation: "TR",
      name: "Tripura",
    },
    {
      abbreviation: "UP",
      name: "Uttar Pradesh",
    },
    {
      abbreviation: "UK",
      name: "Uttarakhand",
    },
    {
      abbreviation: "WB",
      name: "West Bengal",
    },
  ];
  const cityList = [{ name: "City-1" }, { name: "City-2" }, { name: "City-3" }];
  const year = 0;
  const years = Array.from(new Array(30), (val, index) => index + year);
  const month = 0;
  const months = Array.from(new Array(12), (val, index) => index + month);
  // user_type_id:1  is As Doctor
  const formFields = {
    // code: Math.floor(1000 + Math.random() * 9000),
    code: patient.code,
    reg_date: moment(currTime).format("DD MMM YYYY : hh:mm a"),
    name: "",    
    email: "",
    phone: "",
    gender: "",
    age: "",
    family_phone: "",
    relationship: "",
    place: "",
    area: "",
    state:  "",
    measure_type: "",
    height: "",
    weight: "",
    bmi: "",
    occupation: "",
    education: "",
    other_occupation: "",
    description: "",
    dm: "",
    hyperlipidemia: "",
    cvd: "",
    ckd: "",
    stroke: "",
    osa: "",
    others: "",
    co_none: "",
    sbp:"",
    dbp:"",
    pulse:"",
    smoking: "",
    smoking_duration: "",
    smoking_months: "",
    smoking_years: "",
    tobacco: "",
    tobacco_duration: "",
    tobacco_months: "",
    tobacco_years: "",
    is_hypertension: "",
    hyper_years: "",
    hyper_months: "",

    hypertension_note: "",
    ace_inhibitors: "",
    arbs: "",
    alpha_blocker: "",
    beta_blocker: "",
    calcium_blocker: "",
    diuretics: "",
    direct_vasolidators: "",
    central_alpha: "",
    medi_none: "",
    thyroid: "",
    direct_renin_inhibitors: "",
    medi_others: "",
    notes: "",
    draft: "",
    user_id: doctorId,
    patient_id: params.id,
  };
  const [basicInfo, setBasicInfo] = useState(formFields);
  const handleChangeBasicInfo = (event) => {
    const target = event.target;
    const value = target.value;

    const values = { ...basicInfo };
    values[target.name] = target.value;

    if (values["measure_type"] === "CM") {
      // clearInput();
      if (target.name == "height") {
        values["bmi"] = (
          (parseFloat(values.weight) /
            parseFloat(target.value * target.value)) *
          10000
        ).toFixed(2);
      }

      if (target.name == "weight") {
        values["bmi"] = (
          (parseFloat(target.value) /
            parseFloat(values.height * values.height)) *
          10000
        ).toFixed(2);
      }
    }

    let totHeightCM = 0;
    let totWeight = 0;
    let bmi = 0;

    if (values["measure_type"] === "FEET") {
      if (target.name == "height") {
        totHeightCM = parseFloat(target.value / 0.3937).toFixed(2);
        totWeight = parseFloat(values["weight"]).toFixed(2);
        console.log("h-cm==", totHeightCM);
        console.log("w-kg1==", totWeight);
        values["bmi"] = (
          (parseFloat(totWeight) / parseFloat(totHeightCM * totHeightCM)) *
          10000
        ).toFixed(2);
        // console.log("BMI-1", bmi);
      }
      if (target.name == "weight") {
        totWeight = parseFloat(values["weight"]).toFixed(2);
        console.log("w-kg2==", totWeight);
        let aheightInCM = parseFloat(values["height"] / 0.3937).toFixed(2);
        values["bmi"] = (
          (parseFloat(target.value) / parseFloat(aheightInCM * aheightInCM)) *
          10000
        ).toFixed(2);
      }
    }
    setBasicInfo(values);
    console.log("basic Info BValue", values);
  };

  const handleChangeCheckBox = (event) => {
    const target = event.target;
    const value = target.value;

    const values = { ...basicInfo };
    //
    if (target.checked) {
      values[target.name] = true;
    } else {
      values[target.name] = false;
    }
    console.log(values);
    setBasicInfo(values);
  };

  const handleChangeRadio = (event, value) => {
    const target = event.target;
    const values = { ...basicInfo };
    values[target.name] = value;
    setBasicInfo(values);
  };

  const handleChangeDetails = (index, event) => {
    // console.log("",index,event.target.name);
    const values = { ...basicInfo };
    values.inputFields[index][event.target.name] = event.target.value;
    values.inputFields[index]["bmi"] =
      values.inputFields[index]["weight"] * values.inputFields[index]["bmi"];
    setBasicInfo(values);
  };


  // useEffect(() => {
  //   console.log("Params Idsssss", params.id);
  //   dispatch(getPatientsByIdAsync({ id: params.id }));
     
  // }, []);

  useEffect(() => {
    console.log("Params Id", params.id);
    if (params.id > 0) {
      console.log("Form Status", formRole);
      dispatch(getPatientsByIdAsync({ id: params.id }))
        .unwrap()
        .then((data) => {
          setBasicInfo({
            id: data.id,
            code: data.code,
            reg_date: moment(currTime).format("DD MMM YYYY : hh:mm a"),
            name: "",
            phone: data.phone,
            email: data.email,
            gender:"",
            age: "",
            family_phone: "",
            relationship: "",
            place: data.place,
            area: data.area,
            state: data.state,
            measure_type: "",
            height: "",
            weight: "",
            bmi: "",
            occupation: "",
            other_occupation: "",
            education: "",
            description: "",
            dm: "",
            hyperlipidemia: "",
            cvd: "",
            ckd: "",
            stroke: "",
            osa: "",
            others: "",
            co_none: "",

            smoking: "",
            smoking_duration: "",
            smoking_months: "",
            smoking_years:"",

            dbp:"",
            sbp:"",
            pulse:"",

            tobacco: "",
            tobacco_duration: "",
            tobacco_months: "",
            tobacco_years: "",

            is_hypertension: "",
            hyper_years: "",
            hyper_months: "",
            hypertension_note: "",
            ace_inhibitors: "",
            arbs: "",
            alpha_blocker: "",
            beta_blocker: "",
            calcium_blocker: "",
            diuretics: "",
            direct_vasolidators: "",
            central_alpha: "",
            medi_none: "",
            thyroid: "",
            thyroid_others:"",
            direct_renin_inhibitors: "",
            medi_others: "",
            notes: "",
            user_id: doctorId,
            patient_id: params.id,
            draft: "",
          }); 
          console.log(data);
        });
    } else {
      console.log("Else Form Status", formRole);
      setBasicInfo({
        code: Math.floor(Math.random() * 10000),
        reg_date: moment(currTime).format("DD MMM YYYY : hh:mm a"),
        name: "",
        phone: "",
        email: "",
        gender: "",
        age: "",
        family_phone: "",
        relationship: "",
        place: "",
        area: "",
        state: "",
        measure_type: "",
        height: "",
        weight: "",
        bmi: "",
        occupation: "",
        other_occupation: "",
        education: "",
        description: "",
        dm: "",
        hyperlipidemia: "",
        cvd: "",
        ckd: "",
        stroke: "",
        osa: "",
        others: "",
        co_none: "",
        sbp:"",
        dbp:"",
        pulse:"",
        smoking: "",
        smoking_duration: "",
        smoking_months: "",
        smoking_years: "",

        tobacco: "",
        tobacco_duration: "",
        tobacco_months: "",
        tobacco_years: "",

        is_hypertension: "",
        hyper_years: "",
        hyper_months: "",
        hypertension_note: "",
        ace_inhibitors: "",
        arbs: "",
        alpha_blocker: "",
        beta_blocker: "",
        calcium_blocker: "",
        diuretics: "",
        direct_vasolidators: "",
        central_alpha: "",
        medi_none: "",
        thyroid: "",
        thyroid_others:"",
        direct_renin_inhibitors: "",
        medi_others: "",
        notes: "",
        user_id: doctorId,
        patient_id: params.id,
        draft: "",
      });
    }
  }, [dispatch]);


  const saveData = async (e, clickStatus) => {
    // setBasicInfo(formFields);
    if (!window.confirm("Are you sure?")) {
      return;
    }
    e.preventDefault();
     
    console.log("Form data", basicInfo);

    if (basicInfo.reg_date === "") {
      return toast.error("Please Select Date!");
    }
    if (basicInfo.name === "") {
      return toast.error("Please Enter Name!");
    }
    if (basicInfo.relationship === "") {
      return toast.error("Please Select Relationship!");
    }
    if (basicInfo.email === "") {
      return toast.error("Please Enter Email!");
    }
    if (basicInfo.phone === "") {
      return toast.error("Please Enter Phone!");
    }
    if (basicInfo.gender === "") {
      return toast.error("Please Select Gender!");
    }
    if (basicInfo.family_phone === "") {
      return toast.error("Please Enter Family Phone!");
    }
    if (basicInfo.place === "") {
      return toast.error("Please Enter Place!");
    }
    if (basicInfo.area === "") {
      return toast.error("Please Select Area!");
    }
    if (basicInfo.state === "") {
      return toast.error("Please Select State!");
    }
    if (basicInfo.height === "") {
      return toast.error("Please Enter Height!");
    }
    if (basicInfo.weight === "") {
      return toast.error("Please Enter Weight!");
    }
    if (basicInfo.occupation === "") {
      return toast.error("Please Select Occupation!");
    }
    if (basicInfo.education === "") {
      return toast.error("Please Select Education!");
    }
    if (
      basicInfo.dm === "" &&
      basicInfo.hyperlipidemia === "" &&
      basicInfo.cvd === "" &&
      basicInfo.ckd === "" &&
      basicInfo.stroke === "" &&
      basicInfo.osa === "" &&
      basicInfo.others === "" &&
      basicInfo.co_none === ""
    ) {
      return toast.error("Please Select Co-morbidities atleast one!");
    }
    if (basicInfo.smoking === "") {
      return toast.error("Please Select Smoking!");
    }
    if (basicInfo.tobacco === "") {
      return toast.error("Please Select Tobacco!");
    }
    if (basicInfo.is_hypertension === "") {
      return toast.error("Please Select Hypertension!");
    }
    if (
      basicInfo.ace_inhibitors === "" &&
      basicInfo.arbs === "" &&
      basicInfo.alpha_blocker === "" &&
      basicInfo.beta_blocker === "" &&
      basicInfo.calcium_blocker === "" &&
      basicInfo.diuretics === "" &&
      basicInfo.direct_vasolidators === "" &&
      basicInfo.central_alpha === "" &&
      basicInfo.medi_none === "" &&
      basicInfo.thyroid === "" &&
      basicInfo.notes === ""
    ) {
      return toast.error("Please Select Medication History atleast one!");
    }
    if (basicInfo.phone === basicInfo.family_phone) {
      return toast.error("Phone and Family Phone Should not be same !");
    }
    if (formRole === "add") {
      // dispatch(addPatientAsync(basicInfo));
      dispatch(addFamilyAsync({ basicInfo, clickStatus }));
    } else {
      console.log("Update Call");
      dispatch(addFamilyAsync({ basicInfo, clickStatus }));
    }
  };

  if(authData.panel==="Patient" || authData.panel==="Doctor"){
    return (
      <>
        <div class="content-wrapper">
          <div class="container-full">
            <section class="content">
              <div class="row">
                <div class="col-lg-12 col-12">
                  <div class="box">
                    {/* <div class="box-header with-border">
                      <h4 class="box-title">Case Record Form </h4>
                    </div> */}
  
                    <div class="box-body">
                      <h4 class="box-title text-info mb-0">
                        <i class="ti-user me-15"></i> Add Family Member of - {patient.name}
                      </h4>
                      <hr class="my-15" />
                      <div class="row">
                        <div
                          class="col-md-3"
                          style={{ display: showStore ? "block" : "none" }}
                        >
                          <div class="form-group">
                            <label class="form-label">PID</label>
                            <input
                              type="text"
                              name="patient_id"
                              value={basicInfo.patient_id}
                              onChange={(event) => handleChangeBasicInfo(event)}
                              readOnly
                              class="form-control"
                              placeholder="Code"
                            />
                          </div>
                        </div>
  
                        <div
                          class="col-md-3"
                          style={{ display: showStore ? "block" : "none" }}
                        >
                          <div class="form-group">
                            <label class="form-label">Case Code</label>
                            <input
                              type="text"
                              name="code"
                              value={basicInfo.code}
                              onChange={(event) => handleChangeBasicInfo(event)}
                              readOnly
                              class="form-control"
                              placeholder="Code"
                            />
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="form-label">Date</label>
                            <input
                              disabled
                              type="text"
                              name="reg_date"
                              value={basicInfo.reg_date}
                              onChange={(event) => handleChangeBasicInfo(event)}
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="form-label">Participant Initials</label>
                            <input
                              type="text"
                              name="name"
                              value={basicInfo.name}
                              onChange={(event) => handleChangeBasicInfo(event)}
                              class="form-control"
                              placeholder="Name"
                            />
                          </div>
                        </div>
  
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="form-label">Relationship</label>
                            <select
                              class="form-select"
                              name="relationship"
                              value={basicInfo.relationship}
                              onChange={(event) => handleChangeBasicInfo(event)}
                            >
                              <option value="Null">Select Relationship</option>
                              <option value="Husband">Husband</option>
                              <option value="Wife">Wife</option>
                              <option value="Father">Father</option>
                              <option value="Mother">Mother</option>
                              <option value="Brother">Brother</option>
                              <option value="Sister">Sister</option>
                              <option value="Son">Son</option>
                              <option value="Daughter">Daughter</option>
                            </select>
                          </div>
                        </div>
  
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="form-label">Email</label>
                            <input
                              type="text"
                              name="email"
                              key={patient.email}
                              defaultValue={patient.email}
                              value={basicInfo.email}

                              // defaultValue={authData.user.email}
                              // value={basicInfo.email}

                              onChange={(event) => handleChangeBasicInfo(event)}
                              class="form-control"
                              placeholder="Email"
                            />
                          </div>
                        </div>
  
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="form-label">Phone</label>
                            <input
                              type="text"
                              name="phone"
                              // key={patient.phone}
                              // defaultValue={patient.phone}                              
                              value={basicInfo.phone}
                              onChange={(event) => handleChangeBasicInfo(event)}
                              class="form-control"
                              placeholder="10 Digit Mobile No"
                              maxlength="10" 
                              pattern="[1-9]{1}[0-9]{9}"
                            />
                          </div>
                        </div>
  
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="form-label">Gender</label>
                            <select
                              class="form-select"
                              name="gender"
                              value={basicInfo.gender}
                              onChange={(event) => handleChangeBasicInfo(event)}
                            >
                              <option value="Null">...Select...</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Others">Others</option>
                            </select>
                          </div>
                        </div>
  
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="">Age</label>
                            <input
                              type="number"
                              maxLength={2}
                              name="age"
                              value={basicInfo.age}
                              onChange={(event) => handleChangeBasicInfo(event)}
                              className="form-control"
                              placeholder="00"
                            />
                          </div>
                        </div>
  
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="form-label">Family Member Phone</label>
                            <input
                              type="text"
                              name="family_phone"
                              value={basicInfo.family_phone}
                              onChange={(event) => handleChangeBasicInfo(event)}
                              class="form-control"
                              placeholder="10 Digit Mobile No"
                              maxlength="10" 
                              pattern="[1-9]{1}[0-9]{9}"
                            />
                          </div>
                        </div>
  
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="form-label">Place</label>
                            <input
                              type="text"
                              name="place"
                              value={basicInfo.place}
                              onChange={(event) => handleChangeBasicInfo(event)}
                              class="form-control"
                              placeholder="Place"
                            />
                          </div>
                        </div>
  
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="form-label">Area</label>
                            <select
                              class="form-select"
                              name="area"
                              value={basicInfo.area}
                              onChange={(event) => handleChangeBasicInfo(event)}
                            >
                              <option value="Null">...Select...</option>
                              <option value="Urban">Urban</option>
                              <option value="Rural">Rural</option>
                            </select>
                          </div>
                        </div>
  
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="form-label">State</label>
                            <select
                              className="form-select"
                              name="state"
                              value={basicInfo.state}
                              onChange={(event) => handleChangeBasicInfo(event)}
                            >
                              <option value="Null">...Select...</option>
                              {stateList.map((item) => (
                                <option key={item.name}>{item.name}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3"></div>
                        {/* <div class="col-md-3">
                        </div> */}
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="form-label">
                              Measure Type (Height)
                            </label>
                            <select
                              className="form-select"
                              name="measure_type"
                              value={basicInfo.measure_type}
                              onChange={(event) => handleChangeBasicInfo(event)}
                            >
                              <option value="">...Select Measure Type...</option>
                              <option value="CM">CM</option>
                              <option value="FEET">FEET</option>
                            </select>
                          </div>
                        </div>
  
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="form-label">Height</label>
                            {basicInfo.measure_type == "" && (
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Select Measure Type "
                                readOnly
                              />
                            )}
                            {basicInfo.measure_type == "CM" && (
                              <input
                                type="number"
                                name="height"
                                value={basicInfo.height}
                                onChange={(event) => handleChangeBasicInfo(event)}
                                class="form-control"
                                placeholder="00"
                                ref={textInput}
                              />
                            )}
  
                            {basicInfo.measure_type == "FEET" && (
                              <select
                                class="form-control"
                                name="height"
                                value={basicInfo.height}
                                onChange={(event) => handleChangeBasicInfo(event)}
                              >
                                <option>Select</option>
  
                                {[...Array(4)].map((data, index) => {
                                  return (
                                    <>
                                      <option value={++iniInch} key={index}>
                                        {index + 4} Feet
                                      </option>
                                      <option value={++iniInch} key={index}>
                                        {index + 4} Feet 1 Inch
                                      </option>
                                      <option value={++iniInch} key={index}>
                                        {index + 4} Feet 2 Inch
                                      </option>
                                      <option value={++iniInch} key={index}>
                                        {index + 4} Feet 3 Inch
                                      </option>
                                      <option value={++iniInch} key={index}>
                                        {index + 4} Feet 4 Inch
                                      </option>
                                      <option value={++iniInch} key={index}>
                                        {index + 4} Feet 5 Inch
                                      </option>
                                      <option value={++iniInch} key={index}>
                                        {index + 4} Feet 6 Inch
                                      </option>
                                      <option value={++iniInch} key={index}>
                                        {index + 4} Feet 7 Inch
                                      </option>
                                      <option value={++iniInch} key={index}>
                                        {index + 4} Feet 8 Inch
                                      </option>
                                      <option value={++iniInch} key={index}>
                                        {index + 4} Feet 9 Inch
                                      </option>
                                      <option value={++iniInch} key={index}>
                                        {index + 4} Feet 10 Inch
                                      </option>
                                      <option value={++iniInch} key={index}>
                                        {index + 4} Feet 11 Inch
                                      </option>
                                    </>
                                  );
                                })}
  
                                {/* <option value={48}>4 Feet </option>
                              <option value={49}>4 Feet 1 Inch </option>
                              <option value={50}>4 Feet 2 Inch</option>
                              <option value={51}>4 Feet 3 Inch </option> */}
                              </select>
                            )}
                          </div>
                        </div>
  
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="form-label">Weight (In K.G)</label>
                            <input
                              type="number"
                              name="weight"
                              value={basicInfo.weight}
                              onChange={(event) => handleChangeBasicInfo(event)}
                              class="form-control"
                              placeholder="00"
                            />
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="form-label">BMI</label>
                            <input
                              type="number"
                              readOnly
                              name="bmi"
                              value={basicInfo.bmi}
                              onChange={(event) => handleChangeBasicInfo(event)}
                              class="form-control"
                              placeholder="00"
                            />
                          </div>
                        </div>
  
                        <div className="col-md-3">
                          <div className="form-group">
                            {/* No in Database */}
                            <label className="form-label">Education</label>
  
                            <select
                              className="form-select"
                              name="education"
                              value={basicInfo.education}
                              onChange={(event) => handleChangeBasicInfo(event)}
                            >
                              <option value="null">...Select Education...</option>
                              <option value="BusiAbove 10th Standardness">
                                Above 10th Standard
                              </option>
                              <option value="Below 10th Standard">
                                Below 10th Standard
                              </option>
                            </select>
                          </div>
                        </div>
  
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="">Occupation</label>
                            <select
                              className="form-select"
                              name="occupation"
                              value={basicInfo.occupation}
                              onChange={(event) => handleChangeBasicInfo(event)}
                            >
                              <option value="null">
                                ...Select Occupation...
                              </option>
                              <option value="Business">Business</option>
                              <option value="Govt Employee">Govt Employee</option>
                              <option value="Private Employee">
                                Private Employee
                              </option>
                              <option value="Cultivator">Cultivator</option>
                              <option value="Teacher">Teacher</option>
                              <option value="Lawyer">Lawyer</option>
                              <option value="Lecturer">Lecturer</option>
                              <option value="Doctor">Doctor</option>
                              <option value="Journalist">Journalist</option>
                              <option value="Politician">Politician</option>
                              <option value="Housewife">Housewife</option>
                              <option value="Retired Person">
                                Retired Person
                              </option>
                              <option value="Others">Others</option>
                            </select>
                          </div>
                        </div>
  
                        {basicInfo.occupation === "Others" && (
                          <div
                            className="col-md-4"
                            style={{
                              display:
                                basicInfo.occupation === "Others"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <label>Other Occupation</label>
                            <input
                              type="text"
                              name="other_occupation"
                              value={basicInfo.other_occupation}
                              placeholder="Occupation"
                              className="form-control"
                              onChange={(event) => handleChangeRadio(event)}
                            />
                          </div>
                        )}
                      </div>
  
                      <h4 class="box-title text-info mb-0 mt-20">
                        <i class="ti-save me-15"></i> Co-morbidities
                      </h4>
                      <hr class="my-15" />

                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="">Thyroid Disorder</label>
                            <select
                              className="form-select"
                              name="thyroid"
                              value={basicInfo.thyroid}
                              onChange={(event) => handleChangeBasicInfo(event)}
                            >
                              <option value="Null">...Select...</option>
                              <option value="Hypothyroidism">Hypothyroidism</option>
                              <option value="Hyperthyroidism">Hyperthyroidism</option>
                              <option value="Others">Others</option>
                              
                            </select>
                          </div>
                        </div>  

                         
                        {basicInfo.thyroid === "Others" && (
                          <div
                            className="col-md-6"
                            style={{
                              display:
                                basicInfo.thyroid === "Others"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <label>Thyroid Other</label>
                            <input
                              type="text"
                              name="thyroid_others"
                              value={basicInfo.thyroid_others}
                              placeholder="Others"
                              className="form-control"
                              onChange={(event) => handleChangeRadio(event)}
                            />
                          </div>
                        )} 

                         
                      </div>
  
                      <div className="row">
                        <div className="demo-checkbox">
                          <input
                            type="checkbox"
                            id="md_checkbox_21"
                            className="filled-in chk-col-success"
                            name="dm"
                            value={basicInfo.dm}
                            onChange={(event) => handleChangeCheckBox(event)}
                          />
                          <label for="md_checkbox_21">DM</label>
  
                          <input
                            type="checkbox"
                            id="md_checkbox_23"
                            className="filled-in chk-col-success"
                            name="hyperlipidemia"
                            value={basicInfo.hyperlipidemia}
                            onChange={(event) => handleChangeCheckBox(event)}
                          />
                          <label for="md_checkbox_23"> Hyperlipidemia</label>
  
                          <input
                            type="checkbox"
                            id="md_checkbox_24"
                            className="filled-in chk-col-success"
                            name="cvd"
                            value={basicInfo.cvd}
                            onChange={(event) => handleChangeCheckBox(event)}
                          />
                          <label for="md_checkbox_24">CVD</label>
  
                          <input
                            type="checkbox"
                            id="md_checkbox_27"
                            className="filled-in chk-col-success"
                            name="ckd"
                            value={basicInfo.ckd}
                            onChange={(event) => handleChangeCheckBox(event)}
                          />
                          <label for="md_checkbox_27">CKD</label>
  
                          <input
                            type="checkbox"
                            id="md_checkbox_29"
                            className="filled-in chk-col-success"
                            name="stroke"
                            value={basicInfo.stroke}
                            onChange={(event) => handleChangeCheckBox(event)}
                          />
                          <label for="md_checkbox_29"> Stroke</label>
  
                          <input
                            type="checkbox"
                            id="md_checkbox_30"
                            className="filled-in chk-col-success"
                            name="osa"
                            value={basicInfo.osa}
                            onChange={(event) => handleChangeCheckBox(event)}
                          />
                          <label for="md_checkbox_30"> OSA</label>
                          <input
                            type="checkbox"
                            id="md_checkbox_40"
                            className="filled-in chk-col-success"
                            name="co_none"
                            value={basicInfo.co_none}
                            onChange={(event) => handleChangeCheckBox(event)}
                          />
                          <label htmlFor="md_checkbox_40">None</label>
  
                          <input
                            type="checkbox"
                            id="md_checkbox_31"
                            className="filled-in chk-col-success"
                            name="others"
                            value={basicInfo.others}
                            onChange={(event) => handleChangeCheckBox(event)}
                          />
                          <label for="md_checkbox_31">Other</label>
                        </div>
  
                        <div className="col-md-6">
                          <div
                            className="form-group"
                            style={{
                              display: basicInfo.others ? "block" : "none",
                            }}
                          >
                            {/* <div className="form-group"> */}
                            <label className="form-label">Description</label>
                            <input
                              type="text"
                              name="description"
                              value={basicInfo.description}
                              onChange={(event) => handleChangeBasicInfo(event)}
                              className="form-control"
                              placeholder="Description"
                            />
                          </div>
                        </div>
                      </div>
  
                      {/* Habit */} 

                      <h4 class="box-title text-info mb-0 mt-20">
                        <i class="ti-save me-15"></i> Habits
                      </h4>
                      <hr class="my-15" />
  
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="">Smoking</label> <br />
                            <input
                              name="smoking"
                              type="radio"
                              id="radio_1"
                              value={basicInfo.smoking}
                              onChange={(event) =>
                                handleChangeRadio(event, "Yes")
                              }
                            />
                            <label htmlFor="radio_1">Yes</label> <br />
                            <input
                              name="smoking"
                              type="radio"
                              id="radio_2"
                              value={basicInfo.smoking}
                              onChange={(event) => handleChangeRadio(event, "No")}
                            />
                            <label htmlFor="radio_2">No</label> <br />
                            <input
                              name="smoking"
                              type="radio"
                              id="radio_3"
                              value={basicInfo.smoking}
                              onChange={(event) =>
                                handleChangeRadio(event, "Reformed")
                              }
                            />
                            <label htmlFor="radio_3">Reformed</label>
                          </div>
                        </div>
                        <div
                          className="col-md-8"
                          style={{
                            display:
                              basicInfo.smoking === "Yes" ||
                              basicInfo.smoking === "Reformed"
                                ? "block"
                                : "none",
                          }}
                        >
                          {/* <div className="form-group" style={{display: showSmokingDuration ? 'block' : 'none' }}> */}
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="">Years</label>
                                <select
                                  className="form-select"
                                  name="smoking_years"
                                  value={basicInfo.smoking_years}
                                  onChange={(event) =>
                                    handleChangeBasicInfo(event)
                                  }
                                >
                                  {years.map((year) => {
                                    return (
                                      <option key={year} value={year} name={year}>
                                        {year}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
  
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="">Months</label>
                                <select
                                  className="form-select"
                                  name="smoking_months"
                                  value={basicInfo.smoking_months}
                                  onChange={(event) =>
                                    handleChangeBasicInfo(event)
                                  }
                                >
                                  {months.map((month) => {
                                    return (
                                      <option
                                        key={month}
                                        value={month}
                                        name={month}
                                      >
                                        {month}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="">Tobacco</label> <br />
                            <input
                              name="tobacco"
                              type="radio"
                              id="radio_4"
                              value={basicInfo.tobacco}
                              onChange={(event) =>
                                handleChangeRadio(event, "Yes")
                              }
                            />
                            <label htmlFor="radio_4">Yes</label> <br />
                            <input
                              name="tobacco"
                              type="radio"
                              id="radio_5"
                              value={basicInfo.tobacco}
                              onChange={(event) => handleChangeRadio(event, "No")}
                            />
                            <label htmlFor="radio_5">No</label> <br />
                            <input
                              name="tobacco"
                              type="radio"
                              id="radio_6"
                              value={basicInfo.tobacco}
                              onChange={(event) =>
                                handleChangeRadio(event, "Reformed")
                              }
                            />
                            <label htmlFor="radio_6">Reformed</label>
                          </div>
                        </div>
  
                        <div
                          className="col-md-8"
                          style={{
                            display:
                              basicInfo.tobacco === "Yes" ||
                              basicInfo.tobacco === "Reformed"
                                ? "block"
                                : "none",
                          }}
                        >
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="">Years</label>
                                <select
                                  className="form-select"
                                  name="tobacco_years"
                                  value={basicInfo.tobacco_years}
                                  onChange={(event) =>
                                    handleChangeBasicInfo(event)
                                  }
                                >
                                  {years.map((year) => {
                                    return (
                                      <option key={year} value={year} name={year}>
                                        {year}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
  
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="">Months</label>
                                <select
                                  className="form-select"
                                  name="tobacco_months"
                                  value={basicInfo.tobacco_months}
                                  onChange={(event) =>
                                    handleChangeBasicInfo(event)
                                  }
                                >
                                  {months.map((month) => {
                                    return (
                                      <option
                                        key={month}
                                        value={month}
                                        name={month}
                                      >
                                        {month}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
  
                          {/* <div className="form-group" style={{display: showTobaccoDuration ? 'block' : 'none' }}> */}
                          {/* <div className="form-group" >
                            <label className="">Years/Duration</label>
                            <input
                              type="text"
                              name="tobacco_duration"
                              value={basicInfo.tobacco_duration}
                              onChange={(event) => handleChangeBasicInfo(event)}
                              className="form-control"
                              placeholder="Duration"
                            />
                          </div> */}
                        </div>
                      </div>
  
                      {/* Habit */}

                      <h4 className="box-title text-info mb-0 mt-20">
                        <i className="ti-save me-15"></i> BP Reading
                      </h4>
                      <hr className="my-15" />

                      <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                              <label className="">SBP (mm Hg)</label>
                              <input type="number" placeholder="00" className="form-control"
                               name="sbp"
                               value={basicInfo.sbp}
                               onChange={(event) => handleChangeBasicInfo(event)}
                              />
                            </div>
                          </div> 
                        <div className="col-md-3">
                            <div className="form-group">
                              <label className="">DBP(mm Hg)</label>
                              <input type="number" placeholder="00" className="form-control"
                               name="dbp"
                               value={basicInfo.dbp}
                               onChange={(event) => handleChangeBasicInfo(event)}
                                />
                            </div>
                          </div> 
                        <div className="col-md-3">
                            <div className="form-group">
                              <label className="">Pulse (beats/ min)</label>
                              <input type="number" placeholder="00" className="form-control" 
                               name="pulse"
                               value={basicInfo.pulse}
                               onChange={(event) => handleChangeBasicInfo(event)}
                               />
                            </div>
                          </div> 

                         
                      </div>
                      
  
                      {/* Hypertension History */}
  
                      <h4 class="box-title text-info mb-0 mt-20">
                        <i class="ti-save me-15"></i> Hypertension History
                      </h4>
                      <hr class="my-15" />
  
                      <div className="row">
                        <div className="col-md-9">
                          <div className="form-group">
                            {/* <label className="form-label">Tobacco</label> <br />  */}
                            <input
                              name="is_hypertension"
                              type="radio"
                              id="radio_7"
                              value={basicInfo.is_hypertension}
                              onChange={(event) =>
                                handleChangeRadio(
                                  event,
                                  "Newly Diagnosed with Hypertension"
                                )
                              }
                            />
                            <label for="radio_7">
                              Newly Diagnosed with Hypertension
                            </label>
  
                            <input
                              name="is_hypertension"
                              type="radio"
                              id="radio_9"
                              value={basicInfo.is_hypertension}
                              onChange={(event) => handleChangeRadio(event, "No")}
                            />
                            <label for="radio_9" style={{ marginLeft: "20px" }}>
                              No
                            </label>
  
                            <input
                              name="is_hypertension"
                              type="radio"
                              id="radio_8"
                              value={basicInfo.is_hypertension}
                              onChange={(event) =>
                                handleChangeRadio(
                                  event,
                                  "Known case of Hypertension"
                                )
                              }
                            />
                            <label for="radio_8" style={{ marginLeft: "20px" }}>
                              Known case of Hypertension
                            </label>
                          </div>
                        </div>
  
                        <div className="col-md-6">
                          <div
                            className="form-group"
                            style={{
                              display:
                                basicInfo.is_hypertension ===
                                "Known case of Hypertension"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label className="">Years</label>
                                    <select
                                      className="form-select"
                                      name="hyper_years"
                                      value={basicInfo.hyper_years}
                                      onChange={(event) =>
                                        handleChangeBasicInfo(event)
                                      }
                                    >
                                      {years.map((year) => {
                                        return (
                                          <option
                                            key={year}
                                            value={year}
                                            name={year}
                                          >
                                            {year}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </div>
  
                                <div className="col-md-4">
                                  <label className="">Months</label>
                                  <select
                                    className="form-select"
                                    name="hyper_months"
                                    value={basicInfo.hyper_months}
                                    onChange={(event) =>
                                      handleChangeBasicInfo(event)
                                    }
                                  >
                                    {months.map((month) => {
                                      return (
                                        <option
                                          key={month}
                                          value={month}
                                          name={month}
                                        >
                                          {month}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
  
                      {/* Hypertension History */}
  
                      {/* Medication History */}
  
                      <h4 class="box-title text-info mb-0 mt-20">
                        <i class="ti-save me-15"></i>Medication History
                      </h4>
                      <hr class="my-15" />
  
                      <div className="row">
                        <div className="demo-checkbox">
                          <input
                            type="checkbox"
                            id="md_checkbox_32"
                            className="filled-in chk-col-success"
                            name="ace_inhibitors"
                            value={basicInfo.ace_inhibitors}
                            onChange={(event) => handleChangeCheckBox(event)}
                          />
                          <label for="md_checkbox_32">ACE Inhibitors</label>
  
                          <input
                            type="checkbox"
                            id="md_checkbox_33"
                            className="filled-in chk-col-success"
                            name="arbs"
                            value={basicInfo.arbs}
                            onChange={(event) => handleChangeCheckBox(event)}
                          />
                          <label for="md_checkbox_33"> (ARBs)</label>
  
                          <input
                            type="checkbox"
                            id="md_checkbox_34"
                            className="filled-in chk-col-success"
                            name="alpha_blocker"
                            value={basicInfo.alpha_blocker}
                            onChange={(event) => handleChangeCheckBox(event)}
                          />
                          <label for="md_checkbox_34">Alpha Blockers</label>
  
                          <input
                            type="checkbox"
                            id="md_checkbox_35"
                            className="filled-in chk-col-success"
                            name="beta_blocker"
                            value={basicInfo.beta_blocker}
                            onChange={(event) => handleChangeCheckBox(event)}
                          />
                          <label for="md_checkbox_35">Beta Blockers</label>
  
                          <input
                            type="checkbox"
                            id="md_checkbox_36"
                            className="filled-in chk-col-success"
                            name="calcium_blocker"
                            value={basicInfo.calcium_blocker}
                            onChange={(event) => handleChangeCheckBox(event)}
                          />
                          <label for="md_checkbox_36">
                            {" "}
                            Calcium Channel Blockers
                          </label>
  
                          <input
                            type="checkbox"
                            id="md_checkbox_37"
                            className="filled-in chk-col-success"
                            name="diuretics"
                            value={basicInfo.diuretics}
                            onChange={(event) => handleChangeCheckBox(event)}
                          />
                          <label for="md_checkbox_37"> Diuretics</label>
  
                          <input
                            type="checkbox"
                            id="md_checkbox_38"
                            className="filled-in chk-col-success"
                            name="direct_vasolidators"
                            value={basicInfo.direct_vasolidators}
                            onChange={(event) => handleChangeCheckBox(event)}
                          />
                          <label for="md_checkbox_38"> Direct Vasodilators</label>
  
                          <input
                            type="checkbox"
                            id="md_checkbox_39"
                            className="filled-in chk-col-success"
                            name="central_alpha"
                            value={basicInfo.central_alpha}
                            onChange={(event) => handleChangeCheckBox(event)}
                          />
                          <label for="md_checkbox_39">
                            {" "}
                            Central Alpha 2 Agonists
                          </label>
                          {/* <br />
                          <input
                            type="checkbox"
                            id="md_checkbox_40"
                            className="filled-in chk-col-success"
                            name="central_alpha"
                            value={basicInfo.central_alpha}
                            onChange={(event) => handleChangeCheckBox(event)}
                          />
                          <label for="md_checkbox_40">
                            {" "}
                            Central Alpha 2 Agonists
                          </label> */}
  
                          <input
                            type="checkbox"
                            id="md_checkbox_41"
                            className="filled-in chk-col-success "
                            name="medi_others"
                            value={basicInfo.medi_others}
                            onChange={(event) => handleChangeCheckBox(event)}
                          />
                          <label
                            for="md_checkbox_41"
                            style={{ marginLeft: "15px" }}
                          >
                            Other
                          </label>
  
                          <input
                            type="checkbox"
                            id="md_checkbox_42"
                            className="filled-in chk-col-success"
                            name="medi_none"
                            // checked={basicInfo.medi_none == 1}
                            value={basicInfo.medi_none}
                            onChange={(event) => handleChangeCheckBox(event)}
                          />
                          <label htmlFor="md_checkbox_42"> None</label>
                        </div>
  
                        <div className="col-md-12">
                          <div
                            className="form-group"
                            style={{
                              display: basicInfo.medi_others ? "block" : "none",
                            }}
                          >
                            <label
                              className="form-label"
                              style={{ marginLeft: "20px" }}
                            >
                              Any other anti-hypertensive class of drugs
                            </label>
                            <input
                              type="text"
                              name="notes"
                              value={basicInfo.notes}
                              onChange={(event) => handleChangeBasicInfo(event)}
                              className="form-control"
                              placeholder="Any other anti-hypertensive class of drugs"
                            />
                          </div>
                        </div>
                      </div>
                      {/* Medication History */}
                    </div>
  
                    {/* <div class="box-footer">
                      <button type="button" class="btn btn-warning me-1">
                        <i class="ti-trash"></i> Cancel
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary"
                        onClick={saveData}
                      >
                        <i class="ti-save-alt"></i> Save
                      </button>
                    </div> */}
  
                    <div className="row">
                    <div className="col-md-8">
                    <hr />
                      {/* <div className="box-footer"> */}
                      {/* <button type="button" className="btn btn-warning me-1">
                        <i className="ti-trash"></i> Cancel
                      </button> */}
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={(e) => {
                          saveData(e, 0);
                        }}
                      >
                        <i className="ti-save-alt"></i> Submit
                      </button>
  
                      {/* <span className="pull-right">
                        <button
                          type="submit"
                          className="btn btn-info"
                          onClick={(e) => {
                            saveData(e, 1);
                          }}
                        >
                          <i className="ti-save-alt"></i> Draft
                        </button>
                      </span> */}
  
                      {/* </div> */}
                    </div>
  
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
  else{
    return(
      <UnAuthorised/>
    )
  }
 
}
