import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { useParams } from "react-router";
import axios from "axios";
import { addBPAsync, getBPRecordsByIdAsync, updateBP } from "../Redux/bpSlice";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import TimePicker from "react-time-picker";

export default function AddBpRecords(props) {
  const patients = useSelector((state) => state.patientSlice.patients);
  const color1 = true;
  const [searchParams] = useSearchParams();
  const [buttonText, setButtonText] = useState("Submit");

  const navigation = useNavigate();
  const [modal, setModal] = useState(false);
  const [maxTime, setMaxTime] = useState("00:00:00");
  const [minTime, setMinTime] = useState("00:00:00");

  const handleClose = () => setModal(false);
  const handleShow = () => setModal(true);

  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const bpRecords = useSelector((state) => state.bpSlice.bpRecords);
  const morningRecords = bpRecords.filter(
    (record) => record.shift == "Morning"
  );
  const eveningRecords = bpRecords.filter(
    (record) => record.shift == "Evening"
  );

  let averageTotalSBPM = 0;
  let averageTotalDBP = 0;
  let averageTotalPulse = 0;

  console.log("logged Data", authData);
  console.log("Patient Bp data", bpRecords);

  let averageMorningSBPM = 0;
  let averageMorningDBP = 0;
  let averageMorningPulse = 0;
  if (morningRecords.length > 0) {
    averageMorningSBPM = morningRecords.reduce(
      (partialSum, record) => parseFloat(partialSum) + parseInt(record.sbp),
      0
    );
    const day1SBP = parseInt(
      morningRecords.filter((record) => record.day == "Day-1")[0]?.sbp
    );
    averageMorningSBPM = parseFloat(
      (averageMorningSBPM - day1SBP) / (morningRecords.length - 1)
    ).toFixed(2);

    averageMorningDBP = morningRecords.reduce(
      (partialSum, record) => parseInt(partialSum) + parseInt(record.dbp),
      0
    );
    const day1DBP = parseInt(
      morningRecords.filter((record) => record.day == "Day-1")[0]?.dbp
    );
    averageMorningDBP = parseFloat(
      (averageMorningDBP - day1DBP) / (morningRecords.length - 1)
    ).toFixed(2);

    averageMorningPulse = morningRecords.reduce(
      (partialSum, record) => parseInt(partialSum) + parseInt(record.pulse),
      0
    );
    const day1Pulse = parseInt(
      morningRecords.filter((record) => record.day == "Day-1")[0]?.pulse
    );
    averageMorningPulse = parseFloat(
      (averageMorningPulse - day1Pulse) / (morningRecords.length - 1)
    ).toFixed(2);
  }

  let averageEveningSBPM = 0;
  let averageEveningDBP = 0;
  let averageEveningPulse = 0;

  if (eveningRecords.length > 0) {
    averageEveningSBPM = eveningRecords.reduce(
      (partialSum, record) => parseFloat(partialSum) + parseInt(record.sbp),
      0
    );
    const day1SBP = parseInt(
      eveningRecords.filter((record) => record.day == "Day-1")[0]?.sbp
    );
    averageEveningSBPM = parseFloat(
      (averageEveningSBPM - day1SBP) / (eveningRecords.length - 1)
    ).toFixed(2);

    averageEveningDBP = eveningRecords.reduce(
      (partialSum, record) => parseInt(partialSum) + parseInt(record.dbp),
      0
    );
    const day1DBP = parseInt(
      eveningRecords.filter((record) => record.day == "Day-1")[0]?.dbp
    );
    averageEveningDBP = parseFloat(
      (averageEveningDBP - day1DBP) / (eveningRecords.length - 1)
    ).toFixed(2);

    averageEveningPulse = eveningRecords.reduce(
      (partialSum, record) => parseInt(partialSum) + parseInt(record.pulse),
      0
    );
    const day1Pulse = parseInt(
      eveningRecords.filter((record) => record.day == "Day-1")[0]?.pulse
    );
    averageEveningPulse = parseFloat(
      (averageEveningPulse - day1Pulse) / (eveningRecords.length - 1)
    ).toFixed(2);
  }

  averageTotalSBPM = parseFloat(averageEveningSBPM);
  // averageTotalSBPM = (parseFloat(averageMorningSBPM) + parseFloat(averageEveningSBPM));
  averageTotalDBP = parseFloat(averageEveningDBP);
  // averageTotalDBP = (parseFloat(averageMorningDBP) + parseFloat(averageEveningDBP))
  averageEveningPulse = parseFloat(averageEveningPulse);
  // averageEveningPulse = (parseFloat(averageMorningPulse) + parseFloat(averageEveningPulse))

  // Both Table Data Average
  // let totalSBM=( (parseFloat(averageMorningSBPM).toFixed(2))+(parseFloat(averageTotalSBPM).toFixed(2)) ) ;
  let final_SBP = (
    (parseFloat(averageMorningSBPM) + parseFloat(averageTotalSBPM)) /
    2
  ).toFixed(2);
  let final_DBP = (
    (parseFloat(averageMorningDBP) + parseFloat(averageTotalDBP)) /
    2
  ).toFixed(2);
  let final_PULSE = (
    (parseFloat(averageMorningPulse) + parseFloat(averageEveningPulse)) /
    2
  ).toFixed(2);

  // const  averageMorningSBPM=

  // const [shift,setShift] = useState()
  const { id } = useParams();
  const params = useParams();
  const [patient, setPatient] = useState([]);
  //let currTime =  new Date().toLocaleString();
  //  let currTime =  new Date().toLocaleString();
  console.log("User Id", authData.user.id);
  console.log("Params Id", params.id);

  useEffect(() => {
    shiftMorning();
  }, []);

  const shiftEvening = () => {
    // setShift("Evening");
    // console.log("Shhh", shift);
    console.log("Evening Records----", eveningRecords); 
    dispatch(getBPRecordsByIdAsync({ id: params.id }));
  };
  const shiftMorning = () => {
    // setShift("Morning");
    // console.log("Shhh", shift);
    console.log("Morning Records----", morningRecords);
    setFormRole("add");
    console.log("Form Status", formRole);
    setButtonText("Submit");
    dispatch(getBPRecordsByIdAsync({ id: params.id }));
  };

  const onEdit = (event, record) => {
    event.preventDefault();
    console.log(record);
    setBasicInfo(record);
    setFormRole("edit");
    setButtonText("Update");
  };

  const [formRole, setFormRole] = useState("add");
  const formFields = {
    shift: "",
    day: "",
    sbp: "",
    dbp: "",
    pulse: "",
    time: "",
    id: id,
  };
  const [basicInfo, setBasicInfo] = useState(formFields);

  const handleChangeBasicInfo = (event) => {
    console.log("Name", event.target.name);
    console.log("Value", event.target.value);

    const values = { ...basicInfo };
    values[event.target.name] = event.target.value;
    console.log("Values", values);
    setBasicInfo(values);
  };

  console.log("Modal Status", modal);
  const saveData = async (e) => {
    e.preventDefault();
    if (basicInfo.shift === "") {
      return toast.error("Please Select Shift");
    }

    if (basicInfo.day === "") {
      return toast.error("Please Select Day");
    }
    if (basicInfo.sbp === "" || basicInfo.sbp === 0) {
      return toast.error("Please Enter SBP");
    }
    if (basicInfo.dbp === "" || basicInfo.dbp === 0) {
      return toast.error("Please Enter DBP");
    }
    if (basicInfo.pulse === "" || basicInfo.pulse === 0) {
      return toast.error("Please Enter Pulse");
    }
    if (basicInfo.time === "" || basicInfo.time === 0) {
      return toast.error("Please Enter Time");
    }
    // console.log("Form data", basicInfo);

    if (formRole === "add") {
      // alert("Shift", shift)
      if (window.confirm("Want to Add Record?")) {
        dispatch(addBPAsync(basicInfo))
          .unwrap()
          .then(() => {
            shiftMorning();
          });
      }
    }
    if (formRole === "edit") {
      // alert("Shift", shift)
      if (window.confirm("Want to Update Record?")) {
        console.log("Update Called");
        dispatch(updateBP(basicInfo))
        .unwrap()
        .then(() => {
          shiftMorning();
        });
        setFormRole("add");
        setButtonText("Submit");
      }
      console.log("No");
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-full">
          <div className="content-header">
            <div className="d-flex align-items-center">
              <div className="me-auto">
                <h4 className="page-title">Add BP Reading</h4>
                {/* <button className="btn btn-info btn-sm" onClick={shiftMorning}>
                  Load BP Records{" "}
                </button> */}
              </div>
            </div>
          </div>

          <section className="content">
            <div className="row">
              <div className="col-12 col-lg-12 col-xl-12">
                <div className="nav-tabs-custom">
                  {/* <ul className="nav nav-tabs">
                    <li>
                      <a className="active" href="#morning" data-bs-toggle="tab" onClick={shiftMorning }>
                        Morning (Before Breakfast)
                      </a>
                    </li>
                     
                  </ul> */}

                  <div className="tab-content">
                    <div className="tab-pane" id="evening"></div>

                    <div className="active tab-pane" id="morning">
                      <div className="table-responsive">
                        <form onSubmit={saveData}>
                          <table className="table mb-0">
                            <tbody>
                              {/* var condition = userType =='Doctor' ? { user_id: userId,patient_id:null } : ""; */}

                              <tr
                                style={{
                                  backgroundColor: searchParams.get("color"),
                                  color: "white",
                                }}
                              >
                                {/* <tr style={{backgroundColor: patients.patient_id===null ? 'red' : 'blue', color: 'white' }}>  */}
                                <th style={{ width: 140 }}>Shift</th>
                                <th style={{ width: 140 }}>Day</th>
                                <th>SBP</th>
                                <th>DBP</th>
                                <th>Pulse</th>
                                <th>Time</th>
                                <th></th>
                              </tr>

                              <tr>
                                <td>
                                  <select
                                    name="shift"
                                    value={basicInfo.shift}
                                    onChange={(event) =>
                                      handleChangeBasicInfo(event)
                                    }
                                    className="form-control"
                                  >
                                    <option value="" selected>
                                      Select Shift
                                    </option>
                                    <option value="Morning" selected>
                                      Morning
                                    </option>
                                    <option value="Evening" selected>
                                      Evening
                                    </option>
                                  </select>
                                </td>
                                <td>
                                  <select
                                    name="day"
                                    value={basicInfo.day}
                                    onChange={(event) =>
                                      handleChangeBasicInfo(event)
                                    }
                                    className="form-control"
                                  >
                                    <option value="">Select Day</option>
                                    <option value="Day-1">Day-1</option>
                                    <option value="Day-2">Day-2</option>
                                    <option value="Day-3">Day-3</option>
                                    <option value="Day-4">Day-4</option>
                                    <option value="Day-5">Day-5</option>
                                    <option value="Day-6">Day-6</option>
                                    <option value="Day-7">Day-7</option>
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="sbp"
                                    value={basicInfo.sbp}
                                    onChange={(event) =>
                                      handleChangeBasicInfo(event)
                                    }
                                    placeholder="0.00"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="dbp"
                                    value={basicInfo.dbp}
                                    onChange={(event) =>
                                      handleChangeBasicInfo(event)
                                    }
                                    placeholder="0.00"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="pulse"
                                    value={basicInfo.pulse}
                                    onChange={(event) =>
                                      handleChangeBasicInfo(event)
                                    }
                                    placeholder="0.00"
                                  />
                                </td>
                                <td>
                                  {/* <TimePicker
                                    onChange={(event) =>
                                      handleChangeBasicInfo(event)
                                    }
                                    maxTime={
                                      basicInfo.shift === "Morning"
                                        ? "00:00:00"
                                        : "12:00:00"
                                    }
                                    minTime={
                                      basicInfo.shift === "Morning"
                                        ? "11:59:00"
                                        : "29:59:00"
                                    }
                                    disableClock={true}
                                    value={basicInfo.time}
                                    openClockOnFocus={false}
                                    name="time"
                                  /> */}
                                  <input
                                    required
                                    className="form-control"
                                    name="time"
                                    type="time"
                                    max={
                                      basicInfo.shift === "Morning"
                                        ? "11:59:00"
                                        : "29:59:00"
                                    }
                                    min={
                                      basicInfo.shift === "Morning"
                                        ? "00:00:00"
                                        : "12:00:00"
                                    }
                                    onChange={(event) =>
                                      handleChangeBasicInfo(event)
                                    }
                                    placeholder="00.00"
                                  />
                                </td>

                                <td>
                                  <button
                                    type="submit"
                                    className="waves-effect btn btn-primary btn-sm"
                                  >
                                    {buttonText}
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </form>
                      </div>
                      <div className="table-responsive">
                        Morning Reading <hr />
                        <table className="table mb-0">
                          <tbody>
                            <tr
                              style={{
                                backgroundColor: searchParams.get("color"),
                                color: "white",
                              }}
                            >
                              <th>Day</th>
                              <th>SBP</th>
                              <th>DBP</th>
                              <th>Pulse</th>
                              <th>Time</th>
                              <th></th>
                            </tr>

                            {morningRecords.map((patient, index) => (
                              <tr key={patient.id}>
                                <td>{patient.day}</td>
                                <td>
                                  <strong>{patient.sbp}</strong>
                                </td>
                                <td>{patient.dbp}</td>
                                <td>{patient.pulse}</td>
                                <td>{patient.time}</td>
                                <td>
                                  {authData.panel === "Admin" ||
                                  authData.panel === "Doctor" ? (
                                    <>
                                      {" "}
                                      <button
                                        className="btn btn-info btn-sm"
                                        onClick={(event) =>
                                          onEdit(event, patient)
                                        }
                                      >
                                        Edit
                                      </button>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              </tr>
                            ))}

                            {/* <tr style={{backgroundColor: authData.user.id==params.id ? 'red' : 'blue' }}> */}
                            <tr style={{ backgroundColor: "white" }}>
                              <th style={{ color: "black" }}>
                                Average <br />{" "}
                                <small>
                                  (Day 2 to Day 7 as per guidelines)
                                </small>
                              </th>
                              <th>
                                <input
                                  style={{
                                    backgroundColor: "white",
                                    border: "none",
                                    color: "black",
                                  }}
                                  type="number"
                                  placeholder="0.00"
                                  disabled
                                  value={averageMorningSBPM}
                                />
                              </th>
                              <th>
                                <input
                                  style={{
                                    backgroundColor: "white",
                                    border: "none",
                                    color: "black",
                                  }}
                                  type="number"
                                  placeholder="0.00"
                                  disabled
                                  value={averageMorningDBP}
                                />
                              </th>
                              <th>
                                <input
                                  style={{
                                    backgroundColor: "white",
                                    border: "none",
                                    color: "black",
                                  }}
                                  type="number"
                                  placeholder="0.00"
                                  disabled
                                  value={averageMorningPulse}
                                />
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      Evening Reading <br />
                      <hr />
                      <div className="table-responsive">
                        <table className="table mb-0">
                          <tbody>
                            <tr
                              style={{
                                backgroundColor: searchParams.get("color"),
                                color: "white",
                              }}
                            >
                              <th>Day</th>
                              <th>SBP</th>
                              <th>DBP</th>
                              <th>Pulse</th>
                              <th>Time</th>
                              <th></th>
                            </tr>

                            {eveningRecords.map((patient, index) => (
                              <tr key={patient.id}>
                                <td>{patient.day}</td>
                                <td>
                                  <strong>{patient.sbp}</strong>
                                </td>
                                <td>{patient.dbp}</td>
                                <td>{patient.pulse}</td>
                                <td>{patient.time}</td>
                                <td>
                                  {authData.panel === "Admin" ||
                                  authData.panel === "Doctor" ? (
                                    <>
                                      {" "}
                                      <button
                                        className="btn btn-info btn-sm"
                                        onClick={(event) =>
                                          onEdit(event, patient)
                                        }
                                      >
                                        Edit
                                      </button>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              </tr>
                            ))}

                            <tr style={{ backgroundColor: "white" }}>
                              {/* <tr style={{backgroundColor: authData.user.id==params.id ? 'red' : 'blue', color: 'white' }}> */}
                              <th>
                                Average <br />{" "}
                                <small>
                                  (Day 2 to Day 7 as per guidelines)
                                </small>
                              </th>

                              <th>
                                <input
                                  style={{
                                    backgroundColor: "white",
                                    border: "none",
                                    color: "black",
                                  }}
                                  type="number"
                                  placeholder="0.00"
                                  disabled
                                  value={parseFloat(averageTotalSBPM).toFixed(
                                    2
                                  )}
                                  // value={averageEveningSBPM}
                                />
                              </th>
                              <th>
                                <input
                                  style={{
                                    backgroundColor: "white",
                                    border: "none",
                                    color: "black",
                                  }}
                                  type="number"
                                  placeholder="0.00"
                                  disabled
                                  value={parseFloat(averageTotalDBP).toFixed(2)}
                                />
                              </th>
                              <th>
                                <input
                                  style={{
                                    backgroundColor: "white",
                                    border: "none",
                                    color: "black",
                                  }}
                                  type="number"
                                  placeholder="0.00"
                                  disabled
                                  value={parseFloat(
                                    averageEveningPulse
                                  ).toFixed(2)}
                                />
                              </th>
                              <th></th>
                              <th></th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <hr />
                      All Evening and Morning Reading
                      <div className="table-responsive">
                        <table className="table mb-0">
                          <tbody>
                            <tr
                              style={{
                                backgroundColor: searchParams.get("color"),
                                color: "white",
                              }}
                            >
                              <th> </th>
                              <th>SBP</th>
                              <th>DBP</th>
                              <th>Pulse</th>
                              <th>Time</th>
                              <th></th>
                            </tr>

                            <tr style={{ backgroundColor: "white" }}>
                              {/* <tr style={{backgroundColor: authData.user.id==params.id ? 'red' : 'blue', color: 'white' }}> */}
                              <th>
                                Average <br />{" "}
                                <small>
                                  (Day 2 to Day 7 as per guidelines)
                                </small>
                              </th>

                              <th>
                                <input
                                  style={{
                                    backgroundColor: "white",
                                    border: "none",
                                    color: "black",
                                  }}
                                  type="number"
                                  placeholder="0.00"
                                  disabled
                                  value={final_SBP}

                                  // value={averageEveningSBPM}
                                />
                              </th>
                              <th>
                                <input
                                  style={{
                                    backgroundColor: "white",
                                    border: "none",
                                    color: "black",
                                  }}
                                  type="number"
                                  placeholder="0.00"
                                  disabled
                                  value={final_DBP}
                                />
                              </th>
                              <th>
                                <input
                                  style={{
                                    backgroundColor: "white",
                                    border: "none",
                                    color: "black",
                                  }}
                                  type="number"
                                  placeholder="0.00"
                                  disabled
                                  value={final_PULSE}
                                />
                              </th>

                              <th></th>
                              <th></th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
