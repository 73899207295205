import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

import { getUsersAsync } from "../Redux/userSlice";
import { getPatientsAsync } from "../Redux/patientSlice";

export default function DoctorDashboard() {
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const patients = useSelector((state) => state.patientSlice.patients);

  let totalCount = 0;
  let members = 0;
  let male = "";
  let female = "";
  if (patients.length > 0) {
    male = patients.filter((record) => record.gender == "Male");
    female = patients.filter((record) => record.gender == "Female");
  }
  members = patients?.reduce(
    (partialSum, detail) =>
      partialSum + (parseInt(detail.family_members?.length) || 0),
    0
  );

  totalCount = parseInt(members) + parseInt(patients.length);

  useEffect(() => {
    dispatch(
      getPatientsAsync({ userType: authData.panel, userId: authData.user.id })
      // getPatientsFamilyMembersAsync({ userType: authData.panel, userId: authData.user.id }),
    );
  }, [dispatch]);

  // console.log("Members", familyMembers.length);

  return (
    <>
      <div className="content-wrapper">
        <div className="container-full">
          <section className="content">
            <div className="row">
              <div className="col-xxxl-12 col-xl-12 col-12">
                <div className="box">
                  <div className="box-body">
                    <div className="d-md-flex align-items-center text-md-start text-center">
                      <div className="me-md-30">
                        <img
                          src="assets/images/lgonew.png"
                          alt=""
                          style={{ height: 120 }}
                        />
                      </div>
                      <div className="d-lg-flex w-p100 align-items-center justify-content-between">
                        <div className="me-lg-10 mb-lg-0 mb-10">
                          <h3 className="mb-0">
                            Welcome to Home BP Monitoring Study
                          </h3>
                          <p className="mb-0 fs-16">
                            {/* Consultoin of a
                            pulmonolgist, spirogrphy, cardiogram */}
                          </p>
                        </div>
                        <div>
                          <a
                            href="#"
                            className="waves-effect waves-light btn btn-primary text-nowrap"
                          >
                            Download App
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="box">
                      <div className="box-body">
                        <div className="d-flex align-items-center">
                          <div className="me-15">
                            <img
                              src="https://medical-admin-template.multipurposethemes.com/images/svg-icon/color-svg/custom-20.svg"
                              alt=""
                              className="w-120"
                            />
                          </div>
                          <div>
                            <Link to="/view-cases?r_type=patient">
                              <h4 className="mb-0">Total Patients</h4>
                            </Link>

                            <h3 className="mb-0">
                              {patients.length
                                ? patients.length
                                : "No Record Found"}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="box">
                      <div className="box-body">
                        <div className="d-flex align-items-center">
                          <div className="me-15">
                            <img
                              src="https://medical-admin-template.multipurposethemes.com/images/svg-icon/color-svg/custom-18.svg"
                              alt=""
                              className="w-120"
                            />
                          </div>
                          <div>
                            <h4 className="mb-0">Patient Gender</h4>
                            <h3 className="mb-0">
                              Male : {male.length ? male.length : "0"} / Female
                              : {female.length ? female.length : "0"}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="box">
                      <div className="box-body">
                        <div className="d-flex align-items-center">
                          <div className="me-15">
                            <img
                              src="https://medical-admin-template.multipurposethemes.com/images/svg-icon/color-svg/custom-20.svg"
                              alt=""
                              className="w-120"
                            />
                          </div>
                          <div>
                          <Link to="/view-cases?r_type=family"> <h4 className="mb-0">Total Family Members</h4></Link>
                           
                            <h3 className="mb-0">
                           
                              <h3>
                                {patients?.reduce(
                                  (partialSum, detail) =>
                                    partialSum +
                                    (parseInt(detail.family_members?.length) ||
                                      0),
                                  0
                                )}
                              </h3>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="box">
                      <div className="box-body">
                        <div className="d-flex align-items-center">
                          <div className="me-15">
                            <img
                              src="https://medical-admin-template.multipurposethemes.com/images/svg-icon/color-svg/custom-20.svg"
                              alt=""
                              className="w-120"
                            />
                          </div>
                          <div>
                          <Link to="/view-cases?r_type=all">Total Cases</Link>
                            <h3 className="mb-0">
                              <h3>{totalCount}</h3>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-12">
                    <div className="box">
                      <div className="box-header with-border">
                        <h4 className="box-title">My Cases</h4>
                        <div className="box-controls pull-right">
                          <div className="lookup lookup-circle lookup-right">
                            <input type="text" name="s" />
                          </div>
                        </div>
                      </div>
                      <div className="box-body no-padding">
                        <div className="table-responsive">
                           <PatientList/>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
