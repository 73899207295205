import React,{useState, useEffect,useCallback} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link,useNavigate } from 'react-router-dom'
import { Navigate } from "react-router-dom";  
import { useParams } from "react-router-dom";

import { getDoctorByIdAsync  } from "../Redux/userSlice";

export default function DoctorProfile() {

  const dispatch = useDispatch();
  const params = useParams();  
  const authData= useSelector((state)=>state.auth); 
  const doctor = useSelector((state) => state.userSlice.user);

  useEffect(() => {
    console.log("Params Id", params.id);
    dispatch(getDoctorByIdAsync({ id: params.id }));
  }, []); 
  
  return (
    <>
      <div className="content-wrapper">
        <div className="container-full">
          {/* <div class="content-header">
            <div class="d-flex align-items-center">
              <div class="me-auto">
                <h4 class="page-title">My Profile</h4>
              </div>
            </div>
          </div> */}

          <section class="content">
            <div class="row">
             

              <div class="col-6 col-lg-6 col-xl-6">
                <div class="box box-widget widget-user">
                  <div class="widget-user-header bg-img bbsr-0 bber-0">
                    <h3 class="widget-user-username text-grey text-center">
                   {doctor.name} <br /> <small>{doctor.phone}</small> <br />  <small>Medical Reg. No. {doctor.reg_no}</small>
                    </h3>
                    
                  </div>
                  {/* <div class="widget-user-image">
                    <img
                      class="rounded-circle"
                      src="/assets/images/user3-128x128.jpg"
                      alt="User Avatar"
                    />
                  </div> */}
                  {/* <div class="box-footer">
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="description-block">
                          <h5 class="description-header">5</h5>
                          <span class="description-text">MEMBERS</span>
                        </div>
                      </div>

                      <div class="col-sm-4 be-1 bs-1">
                        <div class="description-block">
                          <h5 class="description-header">2</h5>
                          <span class="description-text">MALE</span>
                        </div>
                      </div>

                      <div class="col-sm-4">
                        <div class="description-block">
                          <h5 class="description-header">3</h5>
                          <span class="description-text">FEMALE</span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div class="box">
                  <div class="box-body box-profile">
                    <div class="row">
                      <div class="col-12">
                        <div>
                          <p>
                            Email :
                            <span class="text-gray ps-10">
                             {doctor.email}
                            </span> 
                          </p>
                          <p>
                            Phone :
                            <span class="text-gray ps-10">{doctor.phone}</span>
                          </p>
                          <p>
                            Place of Work :
                            <span class="text-gray ps-10">{doctor.pop}</span>
                          </p>
                          <p>
                            State :
                            <span class="text-gray ps-10">{doctor.state}, India</span>
                          </p>
                          <p>
                            Center Code :
                            <span class="text-gray ps-10">{doctor.center_code}</span>
                          </p>
                        </div>
                      </div>
                      <div class="col-12">
                        {/* <Link to="/view-cases" className="btn btn-primary text-center">View Patients</Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6 col-lg-6 col-xl-6">
                <div class="box box-widget widget-user">
                      <div class="widget-user-header bg-img bbsr-0 bber-0">
                        {
                            
                        }
                      </div>
                 </div>
               </div>

              
            </div>
          </section>
        </div>
      </div>
    </>
  )
}
