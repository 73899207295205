import { configureStore } from "@reduxjs/toolkit"; 
import authSlice from "./authSlice"; 
import messageSlice from "./messageSlice"; 
import masterSlice from "./masterSlice";
import patientSlice from "./patientSlice";
import userSlice from "./userSlice";
import bpSlice from "./bpSlice";

export default configureStore({
  reducer: {
    auth: authSlice.reducer,
    master: masterSlice,
    userSlice : userSlice, 
    message: messageSlice.reducer,
    patientSlice : patientSlice,
    bpSlice : bpSlice,
  },
});
