 import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from "moment";
import tableToCSV from "../Components/TableToCSV";

import { getUsersAsync } from "../Redux/userSlice";

export default function DoctorList() {
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const users = useSelector((state) => state.userSlice.users);
  console.log("User Type", authData.panel);
   

  useEffect(() => {
    dispatch(getUsersAsync());
  }, [dispatch]);
 
 return (
    <>

    {authData.panel ==="Admin" && (<div className="box-header with-border">
         
    {
           authData.panel==="Admin" && ( <div class="box-header with-border">          
            <button onClick={() => tableToCSV ("table-to-xls")} className="btn btn-success">Download</button>            
           </div> )
        }
				</div> )}
     
     
      <div className="table-responsive">
        <table className="table table-hover" id="table-to-xls">
          <tbody>
            <tr className="bg-info-light">
              <th>Sl. No</th>
              <th style={{ width:200 }}>Doctor Name</th>               
              <th>Phone</th>
              <th>Email</th>
              <th>Reg. No</th>
              <th>Center Code</th>
              <th>Place of Practice</th> 
              <th>Hospita/Institute</th>
              <th>State</th>
              <th>Registered At</th>
              <th>Cases</th>

              <th></th>
            </tr>

            {users.map((doctor, index) => (
              <tr key={doctor.id}>
                <td>{++index}</td>
                <td >
                <Link                    
                    to={`/doctor-profile/${doctor.id}`}                     
                  >
                   {doctor.name}
                  </Link>
                </td>
                
                <td> {doctor.phone} </td>
                <td> {doctor.email} </td>
                <td>{doctor.reg_no}</td>
                <td>{doctor.center_code}</td>
                <td>{doctor.pop}</td>
                <td>{doctor.hospital_name}</td>
                <td>{doctor.state}</td>
                <td>{moment(doctor.createdAt).format("DD MMM YYYY")}</td>
                <td><span className="badge badge-pill badge-success ">{doctor.patients.length}</span> </td>
                <td>
                {/* <Link                    
                    to={`/doctor-profile/${doctor.id}`} 
                    className="waves-effect btn btn-primary btn-sm"
                  >
                    View Profile
                  </Link> */}
                   
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
