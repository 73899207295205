import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";

export default function PatientMenu(props) {
  const authData = useSelector((state) => state.auth);

  return (
    <>
     <aside className="main-sidebar">
        <section className="sidebar position-relative">
          <div className="multinav">
            <div className="multinav-scroll">
              <ul className="sidebar-menu" data-widget="tree">
                <li>
                  <Link to="/patient-dashboard">
                    <i className="icon-Layout-4-blocks">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span>Dashboard</span> 
                  </Link> 
                </li>

                <li className="">
                  <Link to="/instructions">
                    <i className="icon-Diagnostics">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                    </i>
                    <span>Instructions</span> 
                  </Link>
                   
                </li>
                <li>
                  <Link
                   to={`/add-bp-records/${authData.user.id}/?color=red`}  uType="self">                  
                    <i className="icon-Compiling">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span>Add Self BP Records</span>                    
                  </Link>                   
                </li>

                <li>
                  <Link to={`/add-family-members/${authData.user.id}`}>
                    <i className="icon-Barcode-read">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span>Add Family Member</span>
                  </Link>
                </li>

              
                <li>
                  <Link to="/family-members">
                    <i className="icon-Barcode-read">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span>View Family Member</span>
                  </Link>
                </li>



               
               
                {/* <li>
                  <a href="reports.html">
                    <i className="icon-Settings-1">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span>Home BP Monitoring Study</span>
                  </a>
                </li> */}
               
                {/* <li className="header">Reports</li> */}
                {/* <li >
                  <Link to="/contact">
                    <i className="icon-Library">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span>Contact</span> 
                  </Link>
                  
                </li> */}
                
                
                
              </ul>

              
            </div>
          </div>
        </section>
      </aside>
       
   </>
  )
}
