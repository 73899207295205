import React from "react";
import { Link } from "react-router-dom";

export default function AdminMenu() {
  return (
    <>
      <aside className="main-sidebar">
        <section className="sidebar position-relative">
          <div className="multinav">
            <div className="multinav-scroll">
              <ul className="sidebar-menu" data-widget="tree">
                <li>
                  <Link to="/admin-dashboard">
                    <i className="icon-Layout-4-blocks">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span>Dashboard</span>
                  </Link>
                </li>

                <li>
                  <Link to="/view-doctors">
                    <i className="icon-Barcode-read">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span>View Doctors</span>
                  </Link>
                </li>

                {/* <li>
                  <Link to="/add-case">
                    <i className="icon-Barcode-read">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span>Add New Case</span>
                  </Link>
                </li> */}
                <li>
                  <Link to="/view-cases?r_type=patient">
                    <i className="icon-Compiling">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span>View Submitted Cases</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/view-cases?r_type=all">
                    <i className="icon-Settings-1">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span>Reports</span>
                  </Link>
                </li> */}
                <li>
                  <Link to="/bp-reports?r_type=all">
                    <i className="icon-Settings-1">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span>BP Reports</span>
                  </Link>
                </li>
                <li className="">
                <Link to="/instructions">
                    <i className="icon-Diagnostics">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                    </i>
                    <span>Instructions</span>
                  </Link>
                </li>
                {/* <li className="header">Reports</li> */}
                <li>
                  <Link to="/contact">
                    <i className="icon-Library">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span>Contact</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="#">
                    <i className="icon-Library">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span>FAQ</span>
                  </Link>
                </li> */}

                {/* <li className="treeview">
                  <a href="#">
                    <i className="icon-Lock-overturning">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span>Accout Settings</span>
                    <span className="pull-right-container">
                      <i className="fa fa-angle-right pull-right"></i>
                    </span>
                  </a>
                  <ul className="treeview-menu">
                    <li>
                      <a href="#">
                        <i className="icon-Commit">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                        My Profile
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="icon-Commit">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                        Edit Profile
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="icon-Commit">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                        Change Password
                      </a>
                    </li>
                   
                  </ul>
                </li> */}
              </ul>
            </div>
          </div>
        </section>
      </aside>
    </>
  );
}
