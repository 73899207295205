
import axios from "axios";
import React,{useState, } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
 
import { sendCreds  } from "../Redux/userSlice";
export default function ForgotPassword(props) {
  
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [user_type, setUserType] = useState("");
  
   const dispatch = useDispatch();
 
   
const sendRequest = async(e) => {
  e.preventDefault();
  if(!phone) return toast.error("Please Enter Registered Phone");
  let payload = {    
    phone: phone,     
  }
  // dispatch(sendCreds({ phone: payload.phone }));
  dispatch(sendCreds(payload));
} 
  
  return (
    <div className="hold-transition theme-primary bg-img">
      <div className="container h-p100 mt-40">
        {/* className="hold-transition theme-primary bg-img" style="background-image: url(assets/images/auth-bg/bg-1.jpg)" */}
        <div className="row align-items-center justify-content-md-center h-p100">
          <div className="col-12">
            <div className="row justify-content-center g-0">
              <div className="col-lg-4 col-md-4 col-12">
                <div className="bg-white rounded10 shadow-lg">
                  <div className="content-top-agile p-20 pb-0">
                    {/* <img src="assets/images/micro.png" alt="" /> */}
                    <img src="assets/images/lgonew.png" alt="" style={{ height : 100 }} />
                  </div>
                  <hr className="my-15" />

                  <div className="content-top-agile p-20 pb-0">
                    <h2 className="text-primary">Forgot Password</h2>
                    <p className="mb-0"></p>
                  </div>
                  <div className="p-40"> 
                  {/* <div class="form-group">
                      <label class="form-label">Login Type</label>
                      <select
                        class="form-select"
                        name="user_type"
                        value={user_type}
                        onChange={(e) => setUserType(e.target.value)}
                      >
                        <option value="0">Select User Type</option>
                        <option value="Doctor">Doctor</option>
                        <option value="Admin">Admin</option>
                        <option value="Patient">Patient</option>
                      </select>
                    </div> */}

                      <div className="form-group">
                        <div className="input-group mb-3">
                          <span className="input-group-text bg-transparent">
                            <i className="ti-user"></i>
                          </span>
                          <input
                            type="number"
                            name="phone" 
                            onChange={(e)=>setPhone( e.target.value)}                           
                            className="form-control ps-15 bg-transparent"
                            placeholder="10 Digit Mobile No"
                          />
                        </div>
                      </div>
                     
                      <div className="row">
                        <div className="col-6">
                          <div className="checkbox">
                            {/* <input type="checkbox" id="basic_checkbox_1" />
                            <label className="basic_checkbox_1">Remember Me</label> */}
                          </div>
                        </div> 
                       
                        <div className="col-12">
                          <button
                            type="button"
                            className="btn btn-primary mt-0"   
                            onClick={sendRequest}                          
                          >
                           SEND PASSWORD
                          </button>
                        </div>
                      </div>
                   
                    <div className="text-center">
                      <p className="mt-15 mb-0">
                        Remember Password
                        <Link
                          to="/Login"
                          className="text-warning ms-5"
                        >
                          Login
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
