import React, { useState, useEffect } from "react";
import ViewCases from "../Pages/ViewCases";
import PatientList from "../Patient/PatientList";
import FamilyMembers from "./FamilyMembers";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import tableToCSV from "../Components/TableToCSV";
import baseApi from "../Http/baseApi";
import {
  updatePatientAsync
} from "../Redux/patientSlice";


// import moment from "moment";

import { getPatientsByIdAsync } from "../Redux/patientSlice";

export default function PatientProfile(props) {
  const patient = useSelector((state) => state.patientSlice.patient); 
   

  const dispatch = useDispatch();
  const params = useParams();  

  useEffect(() => {
    console.log("Params Id", params.id);
    console.log("Patient Data", patient.draft);
    dispatch(getPatientsByIdAsync({ id: params.id }))
     
  }, []); 

  const formFields = {
    id : params.id,
    is_dose_change: "",
    dosage_name: ""
  }
  
  const [basicInfo, setBasicInfo] = useState(formFields);

  const handleChangeBasicInfo = (event) => { 
    const values = { ...basicInfo };
    values[event.target.name] = event.target.value;
    console.log("=====",values);
    setBasicInfo(values);
    
  };

  const updateData = async(e)=>{
    e.preventDefault();
    // console.log("Form data", basicInfo) 

    // if ( basicInfo.name === "" ) {
    //   return toast.error("All Fields are Required !");
    // } 

      try{
          dispatch(updatePatientAsync(basicInfo));
       }
       catch(err){
         console.log("error",err.response)
         if(err.response.status === 409){
          // console.log("exist");
          toast.error(err.response.data.message); 
         }
    } 


  }

  return (
    <>
      <div className="content-wrapper">
        <div className="container-full">
          <div class="content-header">
            <div class="d-flex align-items-center">
              <div class="me-auto">
                <h4 class="page-title">Profile </h4>
              </div>

              <span className="pull-right">
                {patient.draft==1 ? <> <Link to={`/edit-case/${params.id}`}>Edit Profile</Link></> : ""}
                {/* <Link to={`/edit-case/${params.id}`}>Edit Profile</Link> */}
              </span>
            </div>
          </div>

          <section class="content">
            <div class="row">
              <div class="col-12 col-lg-5 col-xl-4">
                <div class="box box-widget widget-user">
                  <div class="widget-user-header bg-img bbsr-0 bber-0">
                    <h3 class="widget-user-username text-grey text-center">
                      {patient.name}
                    </h3>

                    <h6 class="widget-user-desc text-grey text-center">
                      {patient.patient_id > 0
                        ? "MLHBP/0000000" +
                          patient.params.id +
                          "-P" +
                          patient.params.id
                        : "MLHBP/0000000" + patient.id + "-P" + patient.id}
                      {/* MLHBP/0000000{patient.id}-P */}
                    </h6>
                    <strong>
                      <h4 className="text-center">
                        <Link to={`/add-bp-records/${patient.id}/?color=red`}>
                          Update BP
                        </Link>
                      </h4>
                    </strong>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-7 col-xl-7">
                <div class="box">
                  <div class="box-body box-profile">
                    <div class="row">
                      <div class="col-12">
                        <div>
                          <p>
                            Email :
                            <span class="text-gray ps-10">{patient.email}</span>
                          </p>
                          <p>
                            Phone :
                            <span class="text-gray ps-10">
                              {" "}
                              {patient.phone}{" "}
                            </span>
                          </p>
                          <p>
                            Address :
                            <span class="text-gray ps-10">
                              {patient.state},{patient.place}, India
                            </span>
                          </p>
                          <p>
                            Created At :
                            <span class="text-gray ps-10">
                              {" "}
                              {moment(patient.createdAt).format(
                                "DD MMM YYYY : hh:mm a"
                              )}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Update */}

              <div className="col-sm-4 col-md-4">
                 
                   <div className="form-group"> 
                      <label className="">Major Drug / Dosage change ?</label>
                      <select
                        className="form-select"
                        name="is_dose_change"
                        value={basicInfo.is_dose_change}
                        onChange={(event) => handleChangeBasicInfo(event)}
                      >
                        <option value="Null">...Select...</option>
                        <option value="No">No</option> 
                        <option value="Yes">Yes</option>
                        
                      </select>
                    </div>
                  
              </div>

                   {basicInfo.is_dose_change === "Yes" && (
                          <div
                            className="col-sm-8 col-md-8"
                            style={{
                              display:
                              basicInfo.is_dose_change === "Yes"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <label>Please mention the drug/ dosage change</label>
                            <input
                              type="text"
                              name="dosage_name"
                              value={basicInfo.dosage_name}
                              placeholder="Please mention here..."
                              className="form-control"
                              onChange={(event) => handleChangeBasicInfo(event)}
                            />
                          </div>
                        )} 

              <div class="col-12 col-lg-12 col-xl-12"> 
                  <div className="form-group"> 
                        <button className="btn btn-success btn-sm" onClick={ updateData }>Update</button>
                    </div> 
              </div>

              {/* Update */}

              <div class="col-12 col-lg-12 col-xl-12">
                <div class="nav-tabs-custom">
                  <ul class="nav nav-tabs">
                    <li>
                      <a class="active" href="#activity" data-bs-toggle="tab">
                        Basic Information
                      </a>
                    </li>

                    <li>
                      <a href="#settings" data-bs-toggle="tab">
                        Family Members
                      </a>
                    </li>
                  </ul>

                  <div class="tab-content">
                    <div class="active tab-pane" id="activity">
                      <div class="box no-shadow">
                        <form
                          class="form-horizontal form-element col-12"
                          style={{ padding: 13 }}
                        >
                          <div class="form-group row">
                            <label for="inputName" class="col-sm-2 form-label">
                              Name
                            </label>

                            <div class="col-sm-10">
                              <input
                                type="text"
                                class="form-control"
                                id="inputName"
                                name="name"
                                value={patient.name}
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="inputEmail" class="col-sm-2 form-label">
                              Email
                            </label>

                            <div class="col-sm-10">
                              <input
                                type="email"
                                class="form-control"
                                value={patient.email}
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="inputPhone" class="col-sm-2 form-label">
                              Phone
                            </label>

                            <div class="col-sm-10">
                              <input
                                type="tel"
                                class="form-control"
                                value={patient.phone}
                                placeholder=""
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    {/*  */}

                    {/*  */}
                    <div class="tab-pane" id="settings">
                      <div class="box no-shadow">
                        <div className="table-responsive ">
                          <button
                            className="btn btn-success btn-sm mt-2 mb-2 p-10"
                            onClick={() => tableToCSV("table-to-xls")}
                          >
                            Download
                          </button>
                          <table
                            className="table mb-0"
                            cellPadding={0}
                            width="100%"
                            id="table-to-xls"
                          >
                            <tbody>
                              <tr className="bg-primary-light">
                                <th>Sl. No</th>
                                <th>Code</th>
                                <th>Family Member Name</th>
                                <th>Patient Phone</th>
                                <th>Family Member Phone</th>
                                <th>Patient Email</th>
                                <th>Gender</th>
                                <th>Action</th>

                                <th></th>
                              </tr>

                              {patient.family_members ? (
                                <>
                                  {patient.family_members.map(
                                    (member, index) => (
                                      <tr key={member.id}>
                                        <td>{++index}</td>
                                        <td>
                                          MLHBP/0000000
                                          {patient.id + "-P" + patient.id}/F
                                          {index}
                                        </td>
                                        <td>
                                          <strong>{member.name}</strong>
                                        </td>
                                        <td>{member.phone}</td>
                                        <td>{member.family_phone}</td>
                                        <td>{member.email}</td>
                                        <td>{member.gender}</td>
                                        <td>
                                          {" "}
                                          <span className="pull-right">
                                            <Link
                                              to={`/add-bp-records/${member.id}/?color=blue`}
                                            >
                                              Update BP
                                            </Link>
                                          </span>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </>
                              ) : (
                                "No Data"
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
