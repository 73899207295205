import React from "react"; 
import PatientList from "../Patient/PatientList";
import AllCases from "../Reports/AllCases";
import {  
  getPatientsAsync 
} from "../Redux/patientSlice" 

export default function Reports() { 
  
  return (
    <>
    <div className="content-wrapper">
      <div className="container-full">
        <section className="content">
           <div className="row">

                {/* <div class="col-12">
                  <div class="box">
                    <div class="box-header with-border"> 
                      <div className="row">
                        <div className="col-md-4">  
                            
                            <select className="form-control" name="" id="">
                                <option value=""> ---- Select Report Type----</option>
                                <option value=""> All Case Records</option>
                                <option value=""> All Doctors Records</option>
                                <option value=""> Cases by Doctor</option>
                            </select> 
                        </div> 
                        <div className="col-md-3">                          
                            <button className="btn btn-info btn-sm">Search Report</button> 
                        </div> 
                      </div>
                    </div> 
                    
                  </div>
                </div> */}



                <div class="col-12">
                  <div class="box">
                    <div class="box-header with-border">
                      <h4 class="box-title">Reports</h4>
                      <div class="box-controls pull-right">
                        {/* <div class="lookup lookup-circle lookup-right">
                          <input type="text" name="s" />
                        </div> */}
                      </div>
                    </div>
                    <div class="box-body no-padding">
                      {/* Call Data */}
                      {/* <AllCases/> */}
                      <PatientList/>
                    </div>
                    
                  </div>
                </div>
              </div>
        </section>
      </div>
    </div>
  </>
  );
}
