import React from "react"; 
import DoctorList from "./DoctorList";
 
export default function Doctors() { 
  
  return (
    <>
    <div className="content-wrapper">
      <div className="container-full">
        <section className="content">
        <div className="row">
                <div class="col-12">
                  <div class="box">
                    <div class="box-header with-border">
                      <h4 class="box-title">Doctor List</h4>
                      <div class="box-controls pull-right">
                        <div class="lookup lookup-circle lookup-right">
                          <input type="text" name="s" />
                        </div>
                      </div>
                    </div>
                    <div class="box-body no-padding">
                      {/* Call Data */}
                      <DoctorList/>
                    </div>
                    
                  </div>
                </div>
              </div>
        </section>
      </div>
    </div>
  </>
  );
}
