import React from 'react'
import FamilyMembers from './FamilyMembers'
import PatientList from './PatientList'

export default function BpMonitoring() {
  return (
    <>
      <div className="content-wrapper">
        <div className="container-full">
          <div class="content-header">
            <div class="d-flex align-items-center">
              <div class="me-auto">
                <h4 class="page-title">Add BP Records</h4>
              </div>
            </div>
          </div>

          <section class="content">
            <div class="row">
              <div class="col-12 col-lg-12 col-xl-12">
                <div class="nav-tabs-custom">
                  <ul class="nav nav-tabs">
                    <li>
                      <a class="active" href="#activity" data-bs-toggle="tab">
                        FAMILY MEMBER
                      </a>
                    </li>
                    <li>
                      <a href="#usertimeline" data-bs-toggle="tab">
                        SELF
                      </a>
                    </li>

                     
                  </ul>

                  <div class="tab-content">
                    <div class="tab-pane" id="usertimeline">
                      <div class="publisher publisher-multi bg-white b-1 mb-30">
                        <textarea
                          class="publisher-input auto-expand"
                          rows="4"
                          placeholder="Write something"
                        ></textarea>
                        <div class="flexbox">
                          <div class="gap-items">
                            <span class="publisher-btn file-group">
                              <i class="fa fa-image file-browser"></i>
                              <input type="file" />
                            </span>
                            <a class="publisher-btn" href="#">
                              <i class="fa fa-map-marker"></i>
                            </a>
                            <a class="publisher-btn" href="#">
                              <i class="fa fa-smile-o"></i>
                            </a>
                          </div>

                          <button class="btn btn-sm btn-bold btn-primary">
                            Post
                          </button>
                        </div>
                      </div>
                    </div>

                    <div class="active tab-pane" id="activity">
                      <div class="box no-shadow">
                        <FamilyMembers />
                      </div>
                    </div>

                    <div class="tab-pane" id="settings">
                      <div class="box no-shadow">
                        <form class="form-horizontal form-element col-12">
                          <div class="form-group row">
                            <label for="inputName" class="col-sm-2 form-label">
                              Name
                            </label>

                            <div class="col-sm-10">
                              <input
                                type="email"
                                class="form-control"
                                id="inputName"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="inputEmail" class="col-sm-2 form-label">
                              Email
                            </label>

                            <div class="col-sm-10">
                              <input
                                type="email"
                                class="form-control"
                                id="inputEmail"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="inputPhone" class="col-sm-2 form-label">
                              Phone
                            </label>

                            <div class="col-sm-10">
                              <input
                                type="tel"
                                class="form-control"
                                id="inputPhone"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <label
                              for="inputExperience"
                              class="col-sm-2 form-label"
                            >
                              Experience
                            </label>

                            <div class="col-sm-10">
                              <textarea
                                class="form-control"
                                id="inputExperience"
                                placeholder=""
                              ></textarea>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label
                              for="inputSkills"
                              class="col-sm-2 form-label"
                            >
                              Skills
                            </label>

                            <div class="col-sm-10">
                              <input
                                type="text"
                                class="form-control"
                                id="inputSkills"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <div class="ms-auto col-sm-10">
                              <div class="checkbox">
                                <input type="checkbox" id="basic_checkbox_1" />
                                <label for="basic_checkbox_1">
                                  {" "}
                                  I agree to the
                                </label>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <a href="#">Terms and Conditions</a>
                              </div>
                            </div>
                          </div>
                          <div class="form-group row">
                            <div class="ms-auto col-sm-10">
                              <button type="submit" class="btn btn-success">
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              
            </div>
          </section>

        </div>
      </div>
    </>
  )
}
