import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseApi from "../Http/baseApi";

export const getUserTypesAsync = createAsyncThunk(
  "master/getUserTypesAsync",
  async () => {
    const response = await baseApi.get(`user-types`);
    // console.log(response.data);
    return response.data;
  }
);

export const addUserTypeAsync = createAsyncThunk(
  "master/addUserTypeAsync",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await baseApi.post("user-types", payload);

      console.log(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const updateUserTypeAsync = createAsyncThunk(
  "master/updateUserTypeAsync",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await baseApi.put(`user-types/${payload.id}`, payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const deleteUserTypeAsync = createAsyncThunk(
  "master/deleteUserTypeAsync",
  async (payload, { rejectWithValue }) => {
    try {
      await baseApi.delete(`user-types/${payload.id}`);
      return payload;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  userTypes: [],
  errorMessage: "",
};

const masterSlice = createSlice({
  name: "master",
  initialState,
  reducers: {},
  extraReducers: {
    [getUserTypesAsync.fulfilled]: (state, action) => {
      console.log("get  data");
      return { ...state, userTypes: action.payload };
    },
    [addUserTypeAsync.fulfilled]: (state, action) => {
      state.userTypes.push(action.payload);
    },

    [updateUserTypeAsync.fulfilled]: (state, action) => {
      const index = state.userTypes.findIndex(
        (userType) => userType.id === action.payload.id
      );
      state.userTypes[index] = action.payload;
    },
    [updateUserTypeAsync.rejected]: (state) => {
      console.log("Error occured");
      return { ...state, errorMessage: "Something went wrong" };
    },
    [deleteUserTypeAsync.fulfilled]: (state, action) => {
      let userTypes = state.userTypes.filter(
        (userType) => userType.id !== action.payload.id
      );

      return { ...state, userTypes: userTypes };
    },
  },
});

export default masterSlice.reducer;
