import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { getUsersAsync } from "../Redux/userSlice";
import { getPatientsAsync } from "../Redux/patientSlice";
import tableToCSV from "../Components/TableToCSV";
import BpRecordRow from "../Components/BpRecordRow";
import { useSearchParams } from "react-router-dom";

export default function BpReport() {
  const [searchParams] = useSearchParams();
  let r_type = searchParams.get("r_type");

  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const patients = useSelector((state) => state.patientSlice.patients);
  let forFamilyIndex = -1;
  let forAllIndex = -1;
  useEffect(() => {
    dispatch(
      getPatientsAsync({ userType: authData.panel, userId: authData.user.id })
    );
  }, [dispatch]);

  return (
    <div>
    

      <div className="content-wrapper">
        <div className="container-full">
          <section className="content">
            <div className="row">

            {authData.panel === "Admin" && (
              <div class="box-header with-border">
                <button
                  onClick={() => tableToCSV("table-to-xls")}
                  className="btn btn-success"
                >
                  Download
                </button>
              </div>
            )}


              <div class="col-12">
                <div class="box">
                  <div class="box-header with-border">
                    <h4 class="box-title">Case List</h4>
                    <div class="box-controls pull-right">
                      <div class="lookup lookup-circle lookup-right">
                        <input type="text" name="s" />
                      </div>
                    </div>
                  </div>
                  <div class="box-body no-padding">
                    <div className="table-responsive ">
                      {/* <table id="example1" class="table table-striped"> */}
                      <table
                        className="table mb-0"
                        cellPadding={0}
                        width="100%"
                        id="table-to-xls"
                      >
                        <tbody>
                          <tr className="bg-primary-light">
                             
                            <th>Sl. No</th>
                            {/* New Fields */}
                            <th>Doctor Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Reg.No</th>
                            <th>Center Code</th>
                            <th>Place of Practice</th>
                            <th>Hospita/Institute</th>
                            <th>State</th>
                            <th>Registered At</th>
                            {/* New Fields */}

                            <th>Code</th>
                            <th style={{ width: "30%" }}>Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Gender</th>
                            <th>Family Phone</th>
                            <th>Place </th>
                            <th>Area</th>
                            <th>State</th>
                            <th>Age</th>
                            <th>Measure Type</th>
                            <th>Height</th>
                            <th>Weight</th>
                            <th>BMI</th>
                            <th>Education</th>
                            <th>Occupation</th>
                            <th>Other Occupation</th>
                            <th>DM</th>
                            <th>Hyperlipidemia</th>
                            <th>CVD</th>
                            <th>CKD</th>
                            <th>Stroke</th>
                            <th>OSA</th>
                            <th>None</th>
                            <th>Other</th>
                            <th>Description</th>
                            <th>Thyroid</th>
                            <th>Smoking</th>
                            <th>Duration</th>
                            <th>Tobacco</th>
                            <th>Duration</th>
                            <th>Hypertension History</th>
                            <th>Duration</th>
                            {/*  */}
                            <th>SBP (mm Hg)</th>
                            <th>DBP(mm Hg)</th>
                            <th>Pulse (beats/ min)</th>

                            {/*  */}
                            <th>ACE</th>
                            <th>ARB</th>
                            <th>Alpha Blocker</th>
                            <th>Beta Blocker</th>
                            <th>Calcium Blocker</th>
                            <th>Diuretics</th>
                            <th>Direct Vasodilators</th>
                            <th>Central Alpha 2 Agonist</th>
                            <th>Others</th>
                            <th>Description</th>
                            <th>None</th>

                            <th>BP Status</th>
                            {["SBP", "DBP", "PULSE"].map((c) => {
                              return [1, 2, 3, 4, 5, 6, 7].map((e) => {
                                return (
                                  <th>
                                    Morning: {c} Day: {e}
                                  </th>
                                );
                              });
                            })}
                            <th>AVERAGE MORNING SBP </th>
                            <th>AVERAGE MORNING DBP</th>
                            <th>AVERAGE MORNING PULSE</th>
                            {["SBP", "DBP", "PULSE"].map((c) => {
                              return [1, 2, 3, 4, 5, 6, 7].map((e) => {
                                return (
                                  <th>
                                    Evening: {c} Day: {e}
                                  </th>
                                );
                              });
                            })}
                            <th>AVERAGE EVENING SBP </th>
                            <th>AVERAGE EVENING DBP</th>
                            <th>AVERAGE EVENING PULSE</th>
                            <th>ALL AVERAGE SBP </th>
                            <th>ALL AVERAGE DBP</th>
                            <th>ALL AVERAGE PULSE</th>
                          </tr>

                          {patients.map((patient, index) => {
                            forAllIndex++;

                            return (
                              <>
                                {(r_type == "all" || r_type == "patient") && (
                                  <BpRecordRow
                                    mainpatient={patient}
                                    type={"patient"}
                                    r_type={r_type}
                                    forAllIndex={forAllIndex}
                                    index={index}
                                  />
                                )}

                                {patient.family_members &&
                                  (r_type == "all" || r_type == "family") &&
                                  patient.family_members.map((member, i) => {
                                    forFamilyIndex++;
                                    forAllIndex++;
                                    return (
                                      <>
                                        <BpRecordRow
                                          r_type={r_type}
                                          mainpatient={patient}
                                          family={member}
                                          type={"family"}
                                          forAllIndex={forAllIndex}
                                          forFamilyIndex={forFamilyIndex}
                                          index={index + "." + ++i}
                                        />
                                      </>
                                    );
                                  })}
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
