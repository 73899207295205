import axios from "axios";
import React,{useEffect, useState, } from "react";
import { Link,useNavigate, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { postLoginAsync } from "../Redux/authSlice";
 
export default function MemberLogin(props) {
  const navigation = useNavigate(); 
 
  const [phone, setPhone] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
   const dispatch = useDispatch();

  useEffect(() => {
   }, [dispatch]);

 

  const handleLogin = (formValue) => {

    if(phone==="" || password===""){
      return toast.error("Enter Credentials")
    }

    if(phone!=password){
      return toast.error("Invalid Credentials")
    }
    setLoading(true);
    dispatch(postLoginAsync({ payload: {
      phone: phone, password: password
    } }))
      .unwrap()
      .then(() => {
        // console.log("User Data", user);
        // props.history.push("/doctor-dashboard");
        // window.location.reload();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  if (isLoggedIn) {
      const user = JSON.parse(localStorage.getItem("userData"));
      // console.log("User Data", user);
      // console.log("User Specific Data", user.user.user_type.name);
      // if(user.user.user_type.name==="Admin"){
      //   return <Navigate to="/patient-dashboard" />;
      // }
      // if(user.user.user_type.name==="Doctor"){
      //   return <Navigate to="/doctor-dashboard" />;
      // }
    
  }
  
  return (
    <div className="hold-transition theme-primary bg-img">
      <div className="container h-p100 mt-40">
        {/* className="hold-transition theme-primary bg-img" style="background-image: url(assets/images/auth-bg/bg-1.jpg)" */}
        <div className="row align-items-center justify-content-md-center h-p100">
          <div className="col-12">
            <div className="row justify-content-center g-0">
              <div className="col-lg-4 col-md-4 col-12">
                <div className="bg-white rounded10 shadow-lg">
                  <div className="content-top-agile p-20 pb-0">
                    <img src="assets/images/micro.png" alt="" />
                  </div>
                  <hr className="my-15" />

                  <div className="content-top-agile p-20 pb-0">
                    <h2 className="text-primary">Login</h2>
                    <p className="mb-0">Sign in to continue</p>
                  </div>
                  <div className="p-40">
                    
                      <div className="form-group">
                        <div className="input-group mb-3">
                          <span className="input-group-text bg-transparent">
                            <i className="ti-user"></i>
                          </span>
                          <input
                            type="text"
                            name="phone"
                            value={phone}
                            onChange={(e)=>setPhone(e.target.value)}
                            className="form-control ps-15 bg-transparent"
                            placeholder="Registered Phone No"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-group mb-3">
                          <span className="input-group-text  bg-transparent">
                            <i className="ti-lock"></i>
                          </span>
                          <input
                            type="password"
                            name="phone"
                            value={password}
                            onChange={(e)=>setPassword(e.target.value)}
                            className="form-control ps-15 bg-transparent"
                            placeholder="Registered Phone No"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="checkbox">
                            {/* <input type="checkbox" id="basic_checkbox_1" />
                            <label className="basic_checkbox_1">Remember Me</label> */}
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="fog-pwd text-end">
                            <Link
                              to="/forgot-password"
                              className="hover-warning"
                            >
                              <i className="ion ion-locked"></i> Forgot
                              password?
                            </Link>
                            <br />
                          </div>
                        </div>

                        <div className="col-6 text-left">
                          <button
                            type="button"
                            className="btn btn-primary mt-0"
                            onClick={handleLogin}
                          >
                            SIGN IN
                          </button>
                        </div>
                      </div>
                   
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
