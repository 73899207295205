import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
 
import { getAllCasesAsync } from "../Redux/patientSlice";

export default function AllCases() {
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const patients = useSelector((state) => state.patientSlice.patients);
  const users = useSelector((state) => state.userSlice.users);

  useEffect(() => {
    dispatch(
        getAllCasesAsync()
    );
  }, [dispatch]);

  console.log("All atients", patients);
  

  return (
    <div>     
    
      {/* {
        authData.user.user_type==="Admin" ? "Admin" : "Doctor"
      } */}

      {/* <div class="box-header with-border">
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-primary"
          table="table-to-xls"
          filename="Data"
          sheet="tablexls"
          buttonText="Download to Excel"/>
				  <h6 class="box-subtitle">Export data to Copy, CSV, Excel, PDF and Print</h6>
				</div>  */}


      <div className="table-responsive ">
        {/* <table id="example1" class="table table-striped"> */}
        <table
          className="table mb-0"
          cellPadding={0}
          width="100%"
          id="table-to-xls"
        //   overflow-x: auto
        style={{overflowX : 'auto'}}
        >
          <tbody>
            <tr className="bg-primary-light">
              <th>Sl. No</th>
              <th>Code</th>
              <th>Name</th>
              <th>Phone/Email</th>
              <th>Gender</th>
              <th>Place</th>
              <th>Rural/Urban</th>
              <th>BP Status</th>
              <th>Created By</th>
              <th></th>
            </tr>

            {
            
            patients.map((patient, index) => (
              <tr key={patient.id}>
                <td>{++index}</td>
                <td>
                  {/* {patient.code} | MLHBP/0000000{patient.id}-P */}
                  MLHBP/0000000{patient.id}-P
                </td>
                <td>
                  <strong>{patient.name}</strong>
                </td>
                <td>
                  {patient.phone} <br /> {patient.email}
                </td>
                <td>{patient.gender}</td>
                <td>{patient.place}</td>
                <td>{patient.area}</td>

                <td> 
               
                {
                  patient.bp_records.length > 0 ? <> <span class="tag label label-success"><small>Started ({patient.bp_records.length})</small> </span></> : <>
                   <span class="tag label label-danger"><small>Not Started</small></span>
                   </>
                } 

                </td>
                <td>{patient.user.name}</td>
                

                <td>
                  <Link
                    to={`/patient-profile/${patient.id}`}
                    className="waves-effect btn btn-primary btn-sm"
                  >
                    View Profile
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
