import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { postLoginAsync } from "../Redux/authSlice";
import baseApi from "../Http/baseApi";

export default function Login(props) {
  const navigation = useNavigate();

  const [modal, setModal] = useState(false);
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [user_type, setUserType] = useState("");
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("Login page");
  }, [dispatch]);

  const formData = {
    user_type: user_type,
    phone: phone,
    password: password,
    accept_terms: 0,
  };
  const handleLogin = async (accept_terms) => {
    if (user_type === "") {
      // setModal(false);
      return toast.error("Select User Type");
    }
    if (phone === "") {
      return toast.error("Enter Phone No");
    }
    if (password === "") {
      return toast.error("Enter Password");
    }
    // if(user_type==="Patient"){
    //   return toast.error("Patient Login Selected")
    // }
    if (accept_terms != 1) { 
      const response = await baseApi.post("auth/signin", formData);
      console.log(response.data);
      if (response.data?.accept_terms == 0) {
        setModal(true);
      } else {
        handleLogin(1);
      }
    } else {
      console.log("Form data", formData);
      setLoading(true);
      dispatch(
        postLoginAsync({
          payload: {
            phone: phone,
            password: password,
            user_type: user_type,
            accept_terms: accept_terms,
          },
        })
      )
        .unwrap()
        .then(() => {
          // console.log("User Data", user);
          // props.history.push("/doctor-dashboard");
          // window.location.reload();
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  if (isLoggedIn) {
    const user = JSON.parse(localStorage.getItem("userData"));
    // console.log("User Data", user);
    // console.log("User Specific Data", user.user.user_type.name);
    if (user.panel === "Admin") {
      return <Navigate to="/admin-dashboard" />;
    }
    if (user.panel === "Doctor") {
      return <Navigate to="/doctor-dashboard" />;
    }
    if (user.panel === "Patient") {
      return <Navigate to="/patient-dashboard" />;
    }
  }

  return (
    <div className="hold-transition theme-primary bg-img">
      {modal && (
        <div
          class="modal center-modal fade show"
          style={{ display: "block" }}
          id="modal-center"
          tabindex="-1"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">CONSENT</h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setModal(false);
                  }}
                ></button>
              </div>
              <div class="modal-body">
                {/* DOCTOR CONSENT */}
                {user_type === "Doctor" ? (
                  <>
                    <h4 style={{ textAlign: "center" }}>
                      {" "}
                      Role of Home BP Monitoring in Diagnosis and Management of
                      Hypertension in India
                    </h4>{" "}
                    <hr />
                    <h3 style={{ textAlign: "center", color: "red" }}>
                      IN HOME Study
                    </h3>
                    <p>
                      I am pleased to give my consent to participate in the IN
                      HOME study and agree to conduct the study in accordance
                      with the protocol.
                    </p>
                  </>
                ) : (
                  <>
                    <h3 style={{ textAlign: "center", color: "red" }}>
                      WELCOME!
                    </h3>
                    <p>
                      I voluntarily agree to take part in the Home Blood
                      Pressure Screening Program. The program is explained to me
                      in a language in which I am fluent and comfortable. I have
                      had a chance to ask questions and they have been answered.
                      I understand that my identity will not be revealed in any
                      information released for publishing and will be kept
                      confidential.
                    </p>
                  </>
                )}

                {/* DOCTOR CONSENT */}

                {/* PATIENT CONSENT */}

                {/* PATIENT CONSENT */}
              </div>
              <div class="modal-footer modal-footer-uniform">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setModal(false);
                  }}
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-primary float-end"
                  onClick={() => {
                    handleLogin(1);
                  }}
                >
                  I Agree and Proceed to Login
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="container h-p100 mt-40">
        {/* className="hold-transition theme-primary bg-img" style="background-image: url(assets/images/auth-bg/bg-1.jpg)" */}
        <div className="row align-items-center justify-content-md-center h-p100">
          <div className="col-12">
            <div className="row justify-content-center g-0">
              <div className="col-lg-4 col-md-4 col-12">
                <div className="bg-white rounded10 shadow-lg">
                  <div className="content-top-agile p-20 pb-0">
                   <span className="pull-left"> <img src="assets/images/lgonew.png" alt=""  style={{ height: 90 }}
                    /></span>
                    <span className="pull-right"><img src="assets/images/micro.png" alt="" /></span>
                  </div>
                  {/* <hr className="my-15" /> */}

                  <div className="content-top-agile p-20 pb-0">
                    <hr  style={{marginTop:"80px"}}/>
                    <h2 className="text-primary" style={{marginTop:"10px"}}>Login</h2>
                    <p className="mb-0">Sign in to continue</p>
                  </div>
                  <div className="p-40">
                    <div class="form-group">
                      <label class="form-label">Login Type</label>
                      <select
                        class="form-select"
                        name="user_type"
                        value={user_type}
                        onChange={(e) => setUserType(e.target.value)}
                      >
                        <option value="0">Select User Type</option>
                        <option value="Admin">Admin</option>
                        <option value="Doctor">Doctor</option>                       
                        <option value="Patient">Patient</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <div className="input-group mb-3">
                        <span className="input-group-text bg-transparent">
                          <i className="ti-user"></i>
                        </span>
                        <input
                          type="text"
                          name="phone"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          className="form-control ps-15 bg-transparent"
                          placeholder="10 Digit Mobile No"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="input-group mb-3">
                        <span className="input-group-text  bg-transparent">
                          <i className="ti-lock"></i>
                        </span>
                        <input
                          type="password"
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="form-control ps-15 bg-transparent"
                          placeholder="Enter Your Password"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="checkbox">
                          {/* <input type="checkbox" id="basic_checkbox_1" />
                            <label className="basic_checkbox_1">Remember Me</label> */}
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="fog-pwd text-end">
                          <Link to="/forgot-password" className="hover-warning">
                            <i className="ion ion-locked"></i> Forgot password?
                          </Link>
                          <br />
                        </div>
                      </div>

                      {user_type != "Admin" && (
                        <div className="col-6 text-left">
                          <button
                            type="button"
                            className="btn btn-primary mt-0"
                            onClick={handleLogin}
                          >
                            SIGN IN
                          </button>
                        </div>
                      )}

                      {user_type === "Admin" && (
                        <div className="col-6 text-left">
                          <button
                            type="button"
                            className="btn btn-primary mt-0"
                            onClick={() => {
                              handleLogin(1);
                            }}
                          >
                            ADMIN SIGN IN
                          </button>
                        </div>
                      )}
                    </div>

                    <div className="text-center">
                      <p className="mt-15 mb-0">
                        Don't have an account?
                        <Link
                          to="/doctor-registration"
                          className="text-warning ms-5"
                        >
                          Sign Up
                        </Link>
                      </p>
                    </div>
                    <p>
                    <div className="text-center">
                      <hr />
                      <p className="mt-15 mb-0">
                       
                        <Link
                          to="/privacy-policy"
                          className="text-warning ms-5"
                        >
                          Privacy Policy
                        </Link>
                      </p>
                    </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
