import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { getUsersAsync } from "../Redux/userSlice";
import { getPatientsDraftsAsync, } from "../Redux/patientSlice";

export default function PatientList() {
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const patients = useSelector((state) => state.patientSlice.drafts);
  

  useEffect(() => {
    dispatch(
        getPatientsDraftsAsync({ userType: authData.panel, userId: authData.user.id })
    );
  }, [dispatch]);

  console.log("===Drafts", patients);
   
  return (
    <div>     
    
      
        {
           authData.panel==="Admin" && ( <div class="box-header with-border">
           <ReactHTMLTableToExcel
             id="test-table-xls-button"
             className="btn btn-primary"
             table="table-to-xls"
             filename="Data"
             sheet="tablexls"
             buttonText="Download to Excel"/>
            
           </div> )
        } 
      <div className="table-responsive ">
        {/* <table id="example1" class="table table-striped"> */}
        <table
          className="table mb-0"
          cellPadding={0}
          width="100%"
          id="table-to-xls"
        >
          <tbody>
            <tr className="bg-primary-light">
              <th>Sl.No</th>
              <th>Code</th>
              <th>Name</th>
              <th>Phone/Email</th>
              <th>Gender</th> 
              <th>Family Phone</th>
              <th>Place </th>
              <th>Area</th>
              <th>State</th>
              <th>Age</th>
              <th>Measure Type</th>
              <th>Height</th>
              <th>Weight</th>
              <th>BMI</th>
              <th>Education</th>
              <th>Occupation</th>
              <th>Other Occupation</th>
              <th>DM</th>
              <th>Hyperlipidemia</th>
              <th>CVD</th>
              <th>CKD</th>
              <th>Stroke</th>
              <th>OSA</th>
              <th>None</th>
              <th>Other</th>
              <th>Description</th>
              <th>Smoking</th>
              <th>Duration</th>
              <th>Tobacco</th>
              <th>Duration</th>
              <th>Hypertension History</th>
              <th>Duration</th>
              <th>ACE</th>
              <th>ARB</th>
              <th>Alpha Blocker</th>
              <th>Beta Blocker</th>
              <th>Calcium Blocker</th>
              <th>Diuretics</th>
              <th>Direct Vasodilators</th>
              <th>Central Alpha 2 Agonist</th>
              <th>Others</th> 
              
              <th>BP Status</th>
              <th>Doctor</th>
              <th></th>
            </tr>

            {
            
            patients.map((patient, index) => (
              <tr key={patient.id}>
                <td>{++index}</td>
                <td>
                  {/* {patient.code} | MLHBP/0000000{patient.id}-P */}
                  MLHBP/0000000{patient.id}-P
                </td>
                <td  style={{backgroundColor: patient.patient_id===null ? "green":"black" , color: 'white' }}>
                  <strong>{patient.name} <br /> 
                  {patient.patient_id===null ? `Self` : "FM"  }
                  {/* {patient.patient_id ? "Self" : `Family Member of `+patient.name } */}
                  
                  </strong>
                </td>
                <td>
                  {patient.phone} <br /> {patient.email}
                </td>
                <td>{patient.gender}</td>
                <td>{patient.family_phone}</td>
                <td>{patient.place}</td>
                <td>{patient.area}</td>
                <td>{patient.state}</td>
                <td>{patient.age} Years</td> 
                <td>{patient.measure_type}</td>
                <td>{patient.height}</td>
                <td>{patient.weight}</td>
                <td>{patient.bmi}</td>
                <td>{patient.education}</td>
                <td>{patient.occupation}</td> 
                <td>{patient.other_occupation ? patient.other_occupation : "Nill"}</td>
                <td>{patient.dm ? patient.dm : "No"}</td>
                <td>{patient.hyperlipidemia ? "Yes" : "No"}</td>
                <td>{patient.cvd ? "Yes" : "No"}</td>
                <td>{patient.ckd ? "Yes" : "No"}</td>
                <td>{patient.stroke ? "Yes" : "No"}</td>
                <td>{patient.osa ? "Yes" : "No"}</td>
                <td>{patient.co_none ? "Yes" : "No"}</td>
                <td>{patient.others ? "Yes" : "No"}</td>
                <td>{patient.description ? patient.description : "Nill"}</td>
                <td>{patient.smoking}</td>
                <td>{patient.smoking_years ? patient.smoking_years : "0"} Years {patient.smoking_months ? patient.smoking_months : "0"} Months</td>
                <td>{patient.tobacco}</td>
                <td>{patient.tobacco_years ? patient.tobacco_years : "0"} Years {patient.tobacco_months ? patient.tobacco_months : "0"} Months</td>
                <td>{patient.is_hypertension ? patient.is_hypertension : ""}</td>
                <td>{patient.hyper_years ? patient.hyper_years : "0"} Years {patient.hyper_years ? patient.hyper_years : "0"} Months</td>
                <td>{patient.ace_inhibitors ? "Yes" : "No"}</td>
                <td>{patient.arbs ? "Yes" : "No"}</td>
                <td>{patient.alpha_blocker ? "Yes" : "No"}</td>
                <td>{patient.beta_blocker ? "Yes" : "No"}</td>
                <td>{patient.calcium_blocker ? "Yes" : "No"}</td>
                <td>{patient.diuretics ? "Yes" : "No"}</td>
                <td>{patient.direct_vasolidators ? "Yes" : "No"}</td>
                <td>{patient.central_alpha ? "Yes" : "No"}</td>
                <td>{patient.others ? "Yes" : "No"}</td>  
               <td> 
               
                {
                  patient.bp_records.length > 0 ? <> <span class="tag label label-success"><small>Started ({patient.bp_records.length})</small> </span></> : <>
                   <span class="tag label label-danger"><small>Not Started</small></span>
                   </>
                } 

                </td>
                <td>{patient.user.name} </td>
                <td>
                  <Link
                    to={`/patient-profile/${patient.id}`}
                    className="waves-effect btn btn-primary btn-sm"
                  >
                    View Profile
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
