import React from 'react'
import { Link } from 'react-router-dom'

export default function DoctorMenu() {
  return (
    <>
     <aside className="main-sidebar">
        <section className="sidebar position-relative">
          <div className="multinav">
            <div className="multinav-scroll">
              <ul className="sidebar-menu menu-font" data-widget="tree">
                <li>
                  <Link to="/doctor-dashboard">
                    <i className="icon-Layout-4-blocks">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span>Dashboard</span>
                   
                  </Link>
                  
                </li>
                <li>
                  <Link to="/add-case">
                    <i className="icon-Barcode-read">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span>Add New Case</span>
                  </Link>
                </li>
                <li>
                  <Link to="/draft-cases">
                    <i className="icon-Compiling">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span>Draft Cases</span>
                    
                  </Link> 
                  
                </li>

                <li>
                <Link to="/view-cases?r_type=patient">
                    <i className="icon-Compiling">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span>View Submitted Cases</span>
                    
                  </Link> 
                  
                </li>
               

               
               
                {/* <li>
                  <a href="reports.html">
                    <i className="icon-Settings-1">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span>Home BP Monitoring Study</span>
                  </a>
                </li> */}
                <li className="">
                  <Link to="/instructions">
                    <i className="icon-Diagnostics">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                    </i>
                    <span>Study Protocol</span> 
                  </Link>
                   
                </li>
                {/* <li className="header">Reports</li> */}
                <li >
                  <Link to="/contact">
                    <i className="icon-Library">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span>Contact</span> 
                  </Link>
                  
                </li>
                {/* <li >
                  <a href="#">
                    <i className="icon-Library">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <span>FAQ</span> 
                  </a>
                  
                </li> */}
                
                 
                
              </ul>

              
            </div>
          </div>
        </section>
      </aside>
    </>
  )
}
