import React, { useEffect, useState,useRef } from "react";
import { Document, Page } from 'react-pdf';
import PDFViewer from 'pdf-viewer-reactjs'
import { useDispatch, useSelector } from "react-redux";
import patientInstruction from "../Documents/patient.pdf"; 
import p from "../Documents/p.jpeg"; 
import page1 from "../Documents/1.jpeg"; 
import page2 from "../Documents/2.jpeg";


import protocol1 from "../Documents/protocol-1.jpg"; 
import protocol2 from "../Documents/protocol-2.jpg"; 
import protocol3 from "../Documents/protocol-3.jpg"; 
import protocol4 from "../Documents/protocol-4.jpg"; 

import fm from "../Documents/fm.jpeg"; 

import PdfViewerComponent from "../Components/PdfViewerComponent";
//  PdfViewerComponent
export default function Instructions(props) {
  const authData = useSelector((state) => state.auth);
  console.log("User data", authData.panel);

    const [document, setDocument] = useState(patientInstruction);    
    const ref = useRef();

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

 
  return (
    <div className="content-wrapper">
      <div className="container-full">
        <section className="content">
          <div className="row">
            <div class="col-12">  
              <div className="App-viewer">
              {/* <img src={p} />   */}
              {
                authData.panel==="Doctor" ? 
                <>
                <img src={protocol1} style={{ marginTop :5 } }/>
                <img src={protocol2} style={{ marginTop :5 } }/> 
                <img src={protocol3} style={{ marginTop :5 } }/>                   
                <img src={protocol4} style={{ marginTop :5 } }/>                   
                </> : 
                <>
                <img src={page2} />  
                </>
              }
               
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
 
  
}
