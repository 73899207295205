import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import messageSlice from "./messageSlice";
import { toast } from "react-toastify";
import baseApi from "../Http/baseApi";
import { Link,useNavigate, Navigate } from "react-router-dom";

const user = JSON.parse(localStorage.getItem("userData"));

// export const getLoggedInUserAsync = createAsyncThunk(
//     'auth/getLoggedInUserAsync',
//     async ()=>{
//          let userData = localStorage.getItem("userData");

//         return userData;

//     }
// );

export const postLoginAsync = createAsyncThunk(
  "auth/postLoginAsync",
  async (data) => {
    const { payload } = data;

    try {
      const response = await baseApi.post("auth/signin", payload );
      // if(response.status===200){
      //   toast.success("Success"); 
      // } 
      return response.data;
    } 
     catch(err){
       console.log("error",err.response)
       if(err.response.status === 404){ 
        // toast.error(err.response.data.message); 
        toast.error("Invalid Credentials!");
       }
     }
     
  }
);
export const logout = createAsyncThunk("auth/logout", async () => {
   localStorage.removeItem("userData");
 
  
});

const initialState = user
  ? { isLoggedIn: true, user: user.user, panel: user.panel }
  : { isLoggedIn: false, user: null, panel: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: {
    // [getLoggedInUserAsync.fulfilled]: (state, action)=>{
    //     console.log('local data found');
    //     return {...state, user: action.payload, isLoggedIn: true, errorMessage:''} ;
    // },

    [postLoginAsync.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      state.panel = action.payload.panel;
      localStorage.setItem("userData", JSON.stringify(action.payload));

      // return {...state, user: action.payload, isLoggedIn: true, errorMessage:''} ;
      // return {...state, companies: action.payload} ;
    },

    [postLoginAsync.rejected]: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.panel = null;

      // return {...state, user: {}, isLoggedIn: false, errorMessage:'Invalid Credentials!'} ;
    },
    [logout.fulfilled]: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.panel = null;
    },
  },
});

export default authSlice;
