import React from "react";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
  return (
    <div>
      <div className="container h-p100 mt-40">
        {/* className="hold-transition theme-primary bg-img" style="background-image: url(assets/images/auth-bg/bg-1.jpg)" */}
        <div className="row ">
          <div className="col-12">
            <div className="row justify-content-center g-0">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="bg-white rounded10 shadow-lg">
                  <div className="content-top-agile p-20 pb-0">
                    <img
                      src="assets/images/lgonew.png"
                      alt=""
                      style={{ height: 61 }}
                    />
                    <img src="assets/images/micro.png" alt="" />
                  </div>
                  <hr className="my-15" />

                  <div className="content-top-agile p-20 pb-0">
                    <h2 className="text-primary">PRIVACY POLICY</h2>
                    <p style={{ textAlign: "justify" }}>
                      We respect your privacy and are committed to protect the
                      privacy and security of your personal data. Micro Labs
                      Limited urges you to carefully read and review this
                      Privacy Policy Statement before accessing or using this
                      Micro Labs Limited website (hereinafter referred to as
                      “Website”). If you proceed to access or use this website,
                      you are agreeing to abide by this Privacy Policy
                      Statement, without any exceptions. You may not access or
                      use this Website if you do not agree to this Privacy
                      Policy Statement. Micro Labs Limited, its subsidiaries,
                      its affiliates and its group companies (hereinafter
                      referred to as “Micro Labs Limited”) reserve the right to
                      add, remove or modify this information at any time without
                      any prior notification.
                    </p>
                    <h4 style={{ textAlign: "left" }}>
                      Collection of Personal Information
                    </h4>
                    <p style={{ textAlign: "justify" }}>
                      <ul>
                        <li>
                          The Website is not designed to collect and/or receive
                          any personal information of you by itself. Micro Labs
                          Limited is not able to identify you personally unless
                          you access Website and/or provide any personal
                          information.
                        </li>
                        <li>
                          Active collection of information: Micro Labs Limited
                          collects personal information that you enter into data
                          fields on Micro Labs Limited Web sites. For example,
                          you may submit or share your name, postal address,
                          e-mail address, investor details, profession,
                          photograph, medical records and/ or other personal
                          information in order to receive information about
                          various subjects and services wherever applicable. To
                          protect your privacy, you should not provide Micro
                          Labs Limited with any information that is not
                          specifically requested. When you register using your
                          other accounts like on Facebook, Twitter, Gmail etc.
                          we shall retrieve information required for your
                          registration from such account to continue to interact
                          with you and to continue providing the services,
                          wherever applicable.
                        </li>
                        <li>
                          Passive Collection of information: Micro Labs Limited
                          Websites may collect information about your visits to
                          Micro Labs Limited Web sites without you actively
                          submitting such information. This information may be
                          collected using various technologies, such as cookies,
                          Internet tags, and web beacons. The Website may
                          capture some of this information, such as the URL of
                          the website you just visited, Internet Protocol (IP)
                          addresses, GPS location data, mobile phone service
                          provider, details of operating system, the browser
                          version your computer, etc. Passive information
                          collection technologies can make your use of the
                          Website easier by allowing Micro Labs Limited to
                          provide better service, customize sites based on
                          consumer preferences, compile statistics, analyse
                          trends, and otherwise administer and improve the
                          Website. Such information collected by these
                          technologies cannot be used to identity you without
                          additional identifiable information.
                        </li>
                      </ul>
                    </p>
                    <h4 style={{ textAlign: "left" }}>
                      Intended Use of Personally Identifiable Information
                    </h4>
                    <p style={{ textAlign: "justify" }}>
                      <ul>
                        <li>
                          Micro Labs Limited will use the personal information
                          you provide through the Website to respond to your
                          questions, have efficient communication and to provide
                          you with efficient service. After you have entered
                          personal information into a form or data field on the
                          Website, Micro Labs Limited may use certain
                          identifying technologies to allow that website to
                          "remember" your personal preferences, such as sections
                          of the Website that you visit frequently and, if you
                          choose, your login credentials.
                        </li>
                        <li>
                          Micro Labs Limited shall collect, store and use your
                          information in compliance with all applicable laws.
                          You may always limit the amount and type of personal
                          information that Micro Labs Limited receives about you
                          by choosing not to enter any personal information into
                          forms or data fields on the Website. Some of our
                          online services can only be provided to you if you
                          provide us with appropriate personal information.
                          Other parts of the Website may ask whether you wish to
                          opt out or opt into our contact lists for offers,
                          promotions and additional services that may be of
                          interest to you. If opted to do so, we may use this
                          information for marketing and promotional purpose. For
                          example, in accordance with applicable law and with
                          your consent, we will use your email address to send
                          you news and newsletters, special offers, and
                          promotions, and to contact you about products or
                          information we think may interest you. We may also
                          reach out to you with response to any queries posted
                          on our website.
                        </li>
                      </ul>

                      <h4 style={{ textAlign: "left" }}>Spamming</h4>
                      <ul>
                        <li>
                          We do not support “spamming". In accordance to your
                          preferences, we may send periodic emails to you if you
                          have asked us to send you information, or if you have
                          provided your details to us while registering for any
                          of our promotional campaigns. You may choose to opt
                          out of receiving marketing-related information through
                          a link we include on emails you receive from us.
                        </li>
                      </ul>

                      <h4 style={{ textAlign: "left" }}>
                        Non-Disclosure of Information
                      </h4>
                      <ul>
                        <li>
                          Personal information on the Website may be accessed by
                          Micro Labs Limited, by certain companies with which
                          Micro Labs Limited may conduct joint programs, and by
                          individuals and entities with whom Micro Labs Limited
                          contracts to carry out business activities for Micro
                          Labs Limited.
                        </li>
                        <li>
                          Micro Labs Limited does not sell or rent your personal
                          Information with anyone else.
                        </li>
                        <li>
                          Micro Labs Limited may share the personal information
                          with third-party, if it is required for further
                          processing or connection to its business. During this
                          time the information shared will be in accordance with
                          the confidentiality agreement with the third party and
                          applicable law for the intended purpose for which the
                          Information was originally collected and shall ensure
                          all such third parties comply with Micro Labs
                          Limited’s Data Protection and Privacy Policy and
                          Procedure.
                        </li>
                        <li>
                          We may release your personal information when we
                          believe release is required to comply with applicable
                          law. We may release personal information if, in our
                          judgment after review, the release is compelled by law
                          or regulation.
                        </li>
                      </ul>

                      <h4 style={{ textAlign: "left" }}>
                        Protection of personal information
                      </h4>
                      <ul>
                        <li>
                          Micro Labs Limited will maintain adequate technical
                          and organizational security measures to protect
                          Personal Information.
                        </li>
                        <li>
                          As a policy, Micro Labs Limited secures each web page
                          that collects personal information; however, the
                          confidentiality of personal information transmitted
                          over the Internet will not be guaranteed. We urge you
                          to exercise caution when transmitting personal
                          information over the Internet.
                        </li>
                        <li>
                          Micro Labs Limited is not obliged to store your
                          Information for a period which is beyond the intended
                          purpose for which such Information was collected or
                          submitted or to stay in compliance to applicable laws
                          and Micro Labs Limited Policies and Procedures.
                        </li>
                      </ul>

                      <h4 style={{ textAlign: "left" }}>
                        Links to other websites
                      </h4>
                      <ul>
                        <li>
                          This Privacy Policy applies only to Micro Labs
                          Limited's websites. Micro Labs Limited may provide
                          links to other websites, which we believe, may be of
                          interest to you. Micro Labs Limited is not responsible
                          for the content on such website, your access to such
                          website links, security of personal information that
                          you provide or any information collected by that
                          website. The risk of accessing such websites is solely
                          yours.
                        </li>
                      </ul>
                      <h4 style={{ textAlign: "left" }}>Cookies</h4>
                      <ul>
                        <li>
                          Cookies are alphanumeric identifiers with small amount
                          of data that is commonly used as an anonymous unique
                          identifier. These are sent to your browser from the
                          website that you visit and are stored on your
                          computer’s hard drive. Please note, a cookie in no way
                          gives us access to your computer/device and cookies
                          cannot access any other information on your
                          computer/device. Our Website uses these cookies to
                          collect information and to improve our Service by
                          making your interaction with us faster They may be
                          used for the purposes of managing your preferences,
                          maintaining and optimizing security, marketing,
                          communication, analytics, and research..
                        </li>
                      </ul>

                      <h4 style={{ textAlign: "left" }}>
                        We majorly use below types of cookies:
                      </h4>
                      <ul>
                        <li>
                          Essential Cookies or Strictly Necessary Cookies –
                          These cookies are essential to the functioning of our
                          Website and for you to move around the Website.
                          Without these cookies, certain features cannot
                          function. No information about your browsing habits is
                          gathered by these cookies.
                        </li>
                        <li>
                          Functional Cookies – These cookies remember how you
                          prefer to use our Website and improve the way our
                          Website works. These remain on your computer/device
                          for a pre-defined period of time.
                        </li>
                        <li>
                          Performance Cookies - Some cookies help us with the
                          performance and design of our Website. For example,
                          these cookies show us statistics, which are the most
                          frequently visited pages on the Website, help us
                          record any difficulties you have with the Website, and
                          whether our publicity is effective or not.
                        </li>
                        <li>
                          Targeting or Tracking Cookies - On certain pages of
                          our Website, we use cookies to help us understand your
                          interests as you browse the Website, so we can tailor
                          and deliver to you a more personalized service in the
                          future. This assists us in delivering relevant,
                          interest-based information to you.
                        </li>
                        <li>
                          At any time, you also have the option to change
                          /control your cookies through your browser settings.
                          However, if you do turn off cookies in your browser,
                          you will not be able to fully experience some of the
                          features our Website.
                        </li>
                      </ul>

                      <h4>
                        Use of our websites and mobile application by children
                      </h4>
                      <p>
                        Micro Labs Limited does not knowingly collect or use any
                        personal information from children (we define "children"
                        as those who are younger than 18 years of age) on this
                        Website. We do not knowingly allow children to
                        communicate with us or use any of our online services.
                        If you are a parent or guardian and become aware that
                        your child has provided us with information,please
                        contact us and we will work with you to address this
                        issue.
                      </p>
                      <h4>Right to your personal information on our Website</h4>
                      <ul>
                        <li>
                          Right to access: You have the right to ask for a copy
                          of your personal data and to verify how we are
                          processing it.
                        </li>
                        <li>
                          Right to rectification: If you believe we have
                          inaccurate or incomplete information about you, you
                          have the right to ask us to correct or update it.
                        </li>
                        <li>
                          Right to be forgotten: In certain circumstances, you
                          have the right to ask us to remove or erase your
                          personal data from our records.
                        </li>
                        <li>
                          Right to object: You have the right to object to
                          processing of your personal data. You also have the
                          right not to be subjected to any automated decision
                          making or profiling.
                        </li>
                        <li>
                          Right to restriction of processing: You have the right
                          to ask us to restrict processing of your personal data
                          in cases where the data is inaccurate, or the
                          processing of the data is unlawful. This does not
                          restrict Micro Labs Limited from processing of your
                          personal data for legal and regulatory requirements.
                        </li>
                        <li>
                          Right to withdraw consent: If we process your personal
                          data based on your consent, you can withdraw your
                          consent at any point of time.
                        </li>
                        <li>
                          Right to portability: You have the right to ask us to
                          transfer your data to you, or any other third party.
                          If you would like to opt out of future communications
                          from application, please contact us by clicking on the
                          "Contact Us" link on the Web site you are visiting. In
                          all communications to Micro Labs Limited, please
                          include the e–mail address used for registration (if
                          applicable), the Website address and explanation of
                          your request. If you would like to delete or amend
                          your personal information and are contacting us by
                          e-mail, please put "Deletion Request" or "Amendment
                          Request", as applicable, in the subject line of the
                          e-mail. We will do our best to respond to all
                          reasonable requests in a timely manner. To exercise
                          any of your right mentioned in this policy please
                          Contact Us.
                        </li>
                      </ul>
                      <h4>Complaints and Grievances</h4>
                      <p>
                        Any complaints, abuse or concerns with regards to the
                        processing of personal data provided by you or breach of
                        these terms must be reported to info@microlabs.in
                      </p>
                      <h4>Change in Policy</h4>
                      <ul>
                        <li>
                          Micro Labs Limited reserves the right to amend this
                          Privacy Policy without prior notice to reflect
                          technological advancements, legal and regulatory
                          changes and good business practices. If Micro Labs
                          Limited changes its privacy practices, a new Privacy
                          Policy will reflect those changes and the effective
                          date of the revised Privacy Policy will be set forth
                          in this paragraph.
                        </li>
                        <li>
                          This Privacy Policy was last updated on 02/09/2020 and
                          is effective as of that date.
                        </li>
                      </ul>
                    </p>
                  </div>
                  <div className="p-40">
                    <div className="text-center">
                      <p className="mt-15 mb-0">
                        <Link to="/login" className="text-warning ms-5">
                          Login
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
