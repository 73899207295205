import React from "react";
import { useSearchParams } from "react-router-dom";
import PatientList from "../Patient/PatientList";
import { getPatientsAsync } from "../Redux/patientSlice";

export default function ViewCases() {
  const [searchParams] = useSearchParams();
  let r_type = searchParams.get("r_type");
  return (
    <>
      <div className="content-wrapper">
        <div className="container-full">
          <section className="content">
            <div className="row">
              <div class="col-12">
                <div class="box">
                  <div class="box-header with-border">
                    <h4 class="box-title">Case List</h4>
                    <div class="box-controls pull-right">
                      <div class="lookup lookup-circle lookup-right">
                        <input type="text" name="s" />
                      </div>
                    </div>
                  </div>
                  <div class="box-body no-padding">
                    {/* Call Data */}
                    <PatientList r_type={r_type} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
