import React from "react";
import { Outlet, Link } from "react-router-dom";
import Header from "./Header";

export default function Layout() {
  return (
    <>
      <div className="">
        <Header />

        <Outlet /> 

        {/* <main>
          <div className="main col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2 ">  
              <Outlet /> 
          </div>
        </main> */}
      </div>
    </>
  );
}
