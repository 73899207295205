import moment from "moment/moment";
import React from "react";
import { Link } from "react-router-dom";

export default function PatientRow(props) {
  let mainpatient = props.mainpatient;
  let family = props.family;
  let type = props.type;
  let index = props.index;
  let r_type = props.r_type;
  let forFamilyIndex = props.forFamilyIndex;
  let forAllIndex = props.forAllIndex;
  let familyIndex = 0;
  let patient = {};
  if (type == "patient") {
    patient = mainpatient;
  } else {
    patient = family;
    familyIndex = index.split(".")[1];
  }

  if (r_type == "family") {
    index = forFamilyIndex;
  }
  if (r_type == "all") {
    index = forAllIndex;
  }
  return (
    <>
      <tr key={patient.id}>
      <td>{++index}</td>
      <td>
            { 

            patient.patient_id===null ? 
            <>
             <Link
            to={`/add-family-members/${patient.id}`}
            className="waves-effect btn btn-primary btn-sm"
          >
            Add Family Member
          </Link>
            </> : ""}

        </td>

        <td>
            {patient.patient_id===null ? 
            <>
             <Link
            to={`/patient-profile/${patient.id}`}
            className="waves-effect btn btn-primary btn-sm"
          >
            View Profile
          </Link>
            </> : ""} 

        </td>
        

        
        <td>{patient.is_dose_change===null || patient.is_dose_change==="" || patient.is_dose_change==="No" ? "No":"Yes"}</td>
        <td>{patient.dosage_name===null ? "Nill" : patient.dosage_name}</td>
        {/* New */}
        <td>{patient.user ? patient.user.name : mainpatient.user.name}</td>
        <td>{patient.user ? patient.user.phone : mainpatient.user.phone}</td>
        <td>{patient.user ? patient.user.email : mainpatient.user.email}</td>
        <td>{patient.user ? patient.user.reg_no : mainpatient.user.reg_no}</td>
        <td>{patient.user ? patient.user.center_code : mainpatient.user.center_code}</td>
        <td>{patient.user ? patient.user.pop : mainpatient.user.pop}</td>
        <td>{patient.user ? patient.user.hospital_name : mainpatient.user.hospital_name}</td>
        <td>{patient.user ? patient.user.state : mainpatient.user.state}</td>
        {/* {moment(doctor.createdAt).format("DD MMM YYYY : hh:mm a")} */}
        <td>{patient.user ? moment(patient.user.createdAt).format("DD MMM YYYY") : moment(mainpatient.user.createdAt).format("DD MMM YYYY")}</td>
        
        {/* New */}
       
        <td>
          {/* {patient.code} | MLHBP/0000000{patient.id}-P */}
          {/* MLHBP/0000000{patient.id}-P */}

          {type == "patient"
            ? "MLHBP/0000000" + mainpatient.id + "-P" + mainpatient.id
            : "MLHBP/0000000" +
              mainpatient.id +
              "-P" +
              mainpatient.id +
              "/F" +
              familyIndex}
          {}
        </td>
        {/* <td  style={{backgroundColor: patient.patient_id===null ? "green":"black" , color: 'white' }}> */}
        <td>
          <strong>
            {patient.name} <br />
            <small style={{ color: "green" }}>
              {" "}
              {patient.patient_id === null ? `(Patient)` : "FM"}
            </small>
            <small style={{ color: "red" }}>
              {" "}
              {patient.draft ? `(Draft)` : ""}
            </small>
            {/* <small style={{ color: 'red'}}>{patient.draft ? `(Draft By) ${patient.user.name}`  : ""}</small> */}
          </strong>
        </td>
        <td>{patient.phone}</td>
        <td>{patient.email}</td>
        <td>{patient.gender}</td>
        <td>{patient.family_phone}</td>
        <td>{patient.place}</td>
        <td>{patient.area}</td>
        <td>{patient.state}</td>
        <td>{patient.age} Years</td>
        <td>{patient.measure_type}</td>
        <td>{patient.height}</td>
        <td>{patient.weight}</td>
        <td>{patient.bmi}</td>
        <td>{patient.education}</td>
        <td>{patient.occupation}</td>
        <td>{patient.other_occupation ? patient.other_occupation : "Nill"}</td>
        <td>{patient.dm ? "Yes" : "No"}</td>
        <td>{patient.hyperlipidemia ? "Yes" : "No"}</td>
        <td>{patient.cvd ? "Yes" : "No"}</td>
        <td>{patient.ckd ? "Yes" : "No"}</td>
        <td>{patient.stroke ? "Yes" : "No"}</td>
        <td>{patient.osa ? "Yes" : "No"}</td>
        <td>{patient.co_none ? "Yes" : "No"}</td>
        <td>{patient.others ? "Yes" : "-"}</td>
        <td>{patient.description ? patient.description : "Nill"}</td>
        <td>{patient.thyroid}</td>
        <td>{patient.thyroid_others}</td>
        <td>{patient.smoking}</td>
        <td>
          {patient.smoking_years ? patient.smoking_years : "0"} Years{" "}
          {patient.smoking_months ? patient.smoking_months : "0"} Months
        </td>
        <td>{patient.tobacco}</td>
        <td>
          {patient.tobacco_years ? patient.tobacco_years : "0"} Years{" "}
          {patient.tobacco_months ? patient.tobacco_months : "0"} Months
        </td>
        <td>{patient.is_hypertension ? patient.is_hypertension : ""}</td>
        <td>
          {patient.hyper_years ? patient.hyper_years : "0"} Years{" "}
          {patient.hyper_years ? patient.hyper_years : "0"} Months
        </td>
        <td>{patient.sbp ? patient.sbp : "-"}</td>
        <td>{patient.dbp ? patient.dbp : "-"}</td>
        <td>{patient.pulse ? patient.pulse : "-"}</td>

        <td>{patient.ace_inhibitors ? "Yes" : "No"}</td>
        <td>{patient.arbs ? "Yes" : "No"}</td>
        <td>{patient.alpha_blocker ? "Yes" : "No"}</td>
        <td>{patient.beta_blocker ? "Yes" : "No"}</td>
        <td>{patient.calcium_blocker ? "Yes" : "No"}</td>
        <td>{patient.diuretics ? "Yes" : "No"}</td>
        <td>{patient.direct_vasolidators ? "Yes" : "No"}</td>
        <td>{patient.central_alpha ? "Yes" : "No"}</td>
        <td>{patient.medi_others ? "Yes" : "No"}</td>
        <td>{patient.notes ? patient.notes : ""}</td>
        <td>{patient.none == 1 ? "Yes" : "No"}</td>
        <td>
          {patient.bp_records?.length > 0 ? (
            <>
              {" "}
              <span class="tag label label-success">
                <small>Started ({patient.bp_records?.length})</small>{" "}
              </span>
            </>
          ) : (
            <>
              <span class="tag label label-danger">
                <small>Not Started</small>
              </span>
            </>
          )}
        </td>
        <td>
           
          {
            patient.patient_id===null && (
              patient.family_members?.length > 0 ? (
                <>
                  <span className="tag label label-success">
                    <small>{patient.family_members?.length}</small>
                  </span>
                </>
              ) : (
                <>
                  <span className="tag label label-danger">
                    <small>{patient.family_members?.length}</small>
                  </span>
                </>
              )
            )
          } 
        </td>
      
        {/* <td>{patient.draft ? "Yes" : "No"}</td> */}
       

        
        
      </tr>
    </>
  );
}
