import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link,Navigate,useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PageNotFound from "../PageNotFound";

import { getFamilyMembersAsync } from "../Redux/patientSlice";
import UnAuthorised from "../UnAuthorised";

export default function FamilyMembers(props) {
  const navigation = useNavigate(); 
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const patients = useSelector((state) => state.patientSlice.patients);

  useEffect(() => {
    // console.log("Family Members Components");    
    dispatch(getFamilyMembersAsync({ userId: authData.user.id }));
  }, [dispatch]);
// console.log("aaa", authData.panel);

if(authData.panel==="Patient"){
  return (
    <div className="content-wrapper">
      <div className="container-full">
        <section className="content">
          <div className="row">
            <div class="col-12">
              <div class="box">
                <div class="box-header with-border">
                  <h4 class="box-title">Family Members List</h4>
                  <div class="box-controls pull-right">
                    <div class="lookup lookup-circle lookup-right">
                      <input type="text" name="s" />
                    </div>
                  </div>
                </div>
                <div class="box-body no-padding">
                  <div class="table-responsive">
                    <table className="table mb-0">
                      <tbody>
                        <tr class="bg-info-light">
                          <th>Sl. No</th>
                          <th>Code</th>
                          <th>Family Member Name</th>
                          <th>Patient Phone</th>
                          <th>Family Member Phone</th>
                          <th>Email</th>
                          <th>Gender</th>
                          <th>Place</th>
                          <th>Rural/Urban</th>
                          <th></th>
                        </tr>

                        {patients.map((patient, index) => (
                          <tr key={patient.id}>
                            <td>{++index}</td>
                            <td>MLHBP/000{patient.patient_id}/F{index}</td>
                            <td> <strong>{patient.name}</strong></td>
                            <td> {patient.phone} </td>
                            <td> {patient.family_phone} </td>
                            <td> {patient.email} </td>
                            <td>{patient.gender}</td>
                            <td>{patient.place}</td>
                            <td>{patient.area}</td>

                            <td>
                              <Link
                                 to={`/add-bp-records/${patient.id}/?color=blue`}
                               
                               
                                class="waves-effect btn btn-primary btn-sm"
                              >
                                Add Records
                              </Link>

                            {/* <Link
                              to={`/patient-profile/${patient.id}`} test={patient.id} target="_blank" 
                              className="waves-effect btn btn-primary btn-sm"
                            >
                              View Profile
                            </Link> */}

                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );

}
else{
  return(
    <UnAuthorised/>
  )
}
  
}
