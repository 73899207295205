import React from "react";

export default function UnAuthorised() {
  return (
    <>
      <div className="content-wrapper">
        <div className="container-full">
          <div class="content-header">
            <div class="d-flex align-items-center">
              <div class="me-auto">
                {/* <h4 class="page-title">You are not Authorised to Access this Page !</h4> */}
                <span> </span>
              </div>
            </div>
          </div>

          <section class="content">
            <div class="row">
              <div class="col-12 col-lg-12 col-xl-12">
                <h1 class="page-title text-center">
                  You are not Authorised to Access this Page !
                </h1>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
