import moment from "moment/moment";
import React from "react";
import { Link } from "react-router-dom";

export default function BpRecordRow(props) {
  let mainpatient = props.mainpatient;
  let family = props.family;
  let type = props.type;
  let index = props.index;
  let r_type = props.r_type;
  let forFamilyIndex = props.forFamilyIndex;
  let forAllIndex = props.forAllIndex;
  let familyIndex = 0;
  let patient = {};
  if (type == "patient") {
    patient = mainpatient;
  } else {
    patient = family;
    familyIndex = index.split(".")[1];
  }

  if (r_type == "family") {
    index = forFamilyIndex;
  }
  if (r_type == "all") {
    index = forAllIndex;
  }

  const morningRecords = patient.bp_records.filter(
    (record) => record.shift == "Morning"
  );
  const eveningRecords = patient.bp_records.filter(
    (record) => record.shift == "Evening"
  );
  
  let averageMorningSBPM = 0;
  let averageMorningDBP = 0;
  let averageMorningPulse = 0;
  if (morningRecords.length > 0) {
    averageMorningSBPM = morningRecords.reduce(
      (partialSum, record) => parseFloat(partialSum) + parseInt(record.sbp),
      0
    );
    const day1SBP = parseInt(
      morningRecords.filter((record) => record.day == "Day-1")[0]?.sbp
    );
    averageMorningSBPM = parseFloat(
      (averageMorningSBPM - day1SBP) / (morningRecords.length - 1)
    ).toFixed(2);

    averageMorningDBP = morningRecords.reduce(
      (partialSum, record) => parseInt(partialSum) + parseInt(record.dbp),
      0
    );
    const day1DBP = parseInt(
      morningRecords.filter((record) => record.day == "Day-1")[0]?.dbp
    );
    averageMorningDBP = parseFloat(
      (averageMorningDBP - day1DBP) / (morningRecords.length - 1)
    ).toFixed(2);

    averageMorningPulse = morningRecords.reduce(
      (partialSum, record) => parseInt(partialSum) + parseInt(record.pulse),
      0
    );
    const day1Pulse = parseInt(
      morningRecords.filter((record) => record.day == "Day-1")[0]?.pulse
    );
    averageMorningPulse = parseFloat(
      (averageMorningPulse - day1Pulse) / (morningRecords.length - 1)
    ).toFixed(2);
  }

  let averageEveningSBPM = 0;
  let averageEveningDBP = 0;
  let averageEveningPulse = 0;

  if (eveningRecords.length > 0) {
    averageEveningSBPM = eveningRecords.reduce(
      (partialSum, record) => parseFloat(partialSum) + parseInt(record.sbp),
      0
    );
    const day1SBP = parseInt(
      eveningRecords.filter((record) => record.day == "Day-1")[0]?.sbp
    );
    averageEveningSBPM = parseFloat(
      (averageEveningSBPM - day1SBP) / (eveningRecords.length - 1)
    ).toFixed(2);

    averageEveningDBP = eveningRecords.reduce(
      (partialSum, record) => parseInt(partialSum) + parseInt(record.dbp),
      0
    );
    const day1DBP = parseInt(
      eveningRecords.filter((record) => record.day == "Day-1")[0]?.dbp
    );
    averageEveningDBP = parseFloat(
      (averageEveningDBP - day1DBP) / (eveningRecords.length - 1)
    ).toFixed(2);

    averageEveningPulse = eveningRecords.reduce(
      (partialSum, record) => parseInt(partialSum) + parseInt(record.pulse),
      0
    );
    const day1Pulse = parseInt(
      eveningRecords.filter((record) => record.day == "Day-1")[0]?.pulse
    );
    averageEveningPulse = parseFloat(
      (averageEveningPulse - day1Pulse) / (eveningRecords.length - 1)
    ).toFixed(2);
  }
 

  return (
    <>
      <tr key={patient.id}> 

        <td>{++index}</td>
        {/* New */}
        <td>{patient.user ? patient.user.name : mainpatient.user.name}</td>
        <td>{patient.user ? patient.user.phone : mainpatient.user.phone}</td>
        <td>{patient.user ? patient.user.email : mainpatient.user.email}</td>
        <td>{patient.user ? patient.user.reg_no : mainpatient.user.reg_no}</td>
        <td>{patient.user ? patient.user.center_code : mainpatient.user.center_code}</td>
        <td>{patient.user ? patient.user.pop : mainpatient.user.pop}</td>
        <td>{patient.user ? patient.user.hospital_name : mainpatient.user.hospital_name}</td>
        <td>{patient.user ? patient.user.state : mainpatient.user.state}</td>
        {/* {moment(doctor.createdAt).format("DD MMM YYYY : hh:mm a")} */}
        <td>{patient.user ? moment(patient.user.createdAt).format("DD MMM YYYY") : moment(mainpatient.user.createdAt).format("DD MMM YYYY")}</td>
        
        {/* New */}
       
        <td>
          {/* {patient.code} | MLHBP/0000000{patient.id}-P */}
          {/* MLHBP/0000000{patient.id}-P */}

          {type == "patient"
            ? "MLHBP/0000000" + mainpatient.id + "-P" + mainpatient.id
            : "MLHBP/0000000" +
              mainpatient.id +
              "-P" +
              mainpatient.id +
              "/F" +
              familyIndex}
          {}
        </td>
        {/* <td  style={{backgroundColor: patient.patient_id===null ? "green":"black" , color: 'white' }}> */}
        <td>
          <strong>
            {patient.name} <br />
            <small style={{ color: "green" }}>
              {" "}
              {patient.patient_id === null ? `(Patient)` : "FM"}
            </small>
            <small style={{ color: "red" }}>
              {" "}
              {patient.draft ? `(Draft)` : ""}
            </small>
            {/* <small style={{ color: 'red'}}>{patient.draft ? `(Draft By) ${patient.user.name}`  : ""}</small> */}
          </strong>
        </td>
        <td>{patient.phone}</td>
        <td>{patient.email}</td>
        <td>{patient.gender}</td>
        <td>{patient.family_phone}</td>
        <td>{patient.place}</td>
        <td>{patient.area}</td>
        <td>{patient.state}</td>
        <td>{patient.age} Years</td>
        <td>{patient.measure_type}</td>
        <td>{patient.height}</td>
        <td>{patient.weight}</td>
        <td>{patient.bmi}</td>
        <td>{patient.education}</td>
        <td>{patient.occupation}</td>
        <td>{patient.other_occupation ? patient.other_occupation : "Nill"}</td>
        <td>{patient.dm ? "Yes" : "No"}</td>
        <td>{patient.hyperlipidemia ? "Yes" : "No"}</td>
        <td>{patient.cvd ? "Yes" : "No"}</td>
        <td>{patient.ckd ? "Yes" : "No"}</td>
        <td>{patient.stroke ? "Yes" : "No"}</td>
        <td>{patient.osa ? "Yes" : "No"}</td>
        <td>{patient.co_none ? "Yes" : "No"}</td>
        <td>{patient.others ? "Yes" : "-"}</td>
        <td>{patient.description ? patient.description : "Nill"}</td>
        <td>{patient.thyroid}</td>
        <td>{patient.smoking}</td>
        <td>
          {patient.smoking_years ? patient.smoking_years : "0"} Years{" "}
          {patient.smoking_months ? patient.smoking_months : "0"} Months
        </td>
        <td>{patient.tobacco}</td>
        <td>
          {patient.tobacco_years ? patient.tobacco_years : "0"} Years{" "}
          {patient.tobacco_months ? patient.tobacco_months : "0"} Months
        </td>
        <td>{patient.is_hypertension ? patient.is_hypertension : ""}</td>
        <td>
          {patient.hyper_years ? patient.hyper_years : "0"} Years{" "}
          {patient.hyper_years ? patient.hyper_years : "0"} Months
        </td>
        <td>{patient.sbp ? patient.sbp : "-"}</td>
        <td>{patient.dbp ? patient.dbp : "-"}</td>
        <td>{patient.pulse ? patient.pulse : "-"}</td>

        <td>{patient.ace_inhibitors ? "Yes" : "No"}</td>
        <td>{patient.arbs ? "Yes" : "No"}</td>
        <td>{patient.alpha_blocker ? "Yes" : "No"}</td>
        <td>{patient.beta_blocker ? "Yes" : "No"}</td>
        <td>{patient.calcium_blocker ? "Yes" : "No"}</td>
        <td>{patient.diuretics ? "Yes" : "No"}</td>
        <td>{patient.direct_vasolidators ? "Yes" : "No"}</td>
        <td>{patient.central_alpha ? "Yes" : "No"}</td>
        <td>{patient.medi_others ? "Yes" : "No"}</td>
        <td>{patient.notes ? patient.notes : ""}</td>
        <td>{patient.none == 1 ? "Yes" : "No"}</td>
        <td>
          {patient.bp_records?.length > 0 ? (
            <>
              {" "}
              <span class="tag label label-success">
                <small>Started ({patient.bp_records?.length})</small>{" "}
              </span>
            </>
          ) : (
            <>
              <span class="tag label label-danger">
                <small>Not Started</small>
              </span>
            </>
          )}
        </td>
          { ['sbp', 'dbp', 'pulse'].map((c)=>{
                      return (
                        [1,2,3,4,5,6,7].map((e)=>{

                        let record=  patient.bp_records.find((rc)=>
                        
                        rc.shift=='Morning' && rc.day=='Day-'+e 
                        )
                          return <th>{record && record[c]}</th>
                        })
                       
                      );
                    }) }
                    <th>{averageMorningSBPM} </th>
                    <th> {averageMorningDBP}</th>
                    <th> {averageMorningPulse}</th>
                    { ['sbp', 'dbp', 'pulse'].map((c)=>{
                      return (
                        [1,2,3,4,5,6,7].map((e)=>{
                          let record=  patient.bp_records.find((rc)=>
                        
                          rc.shift=='Evening' && rc.day=='Day-'+e 
                          )
                            return <th>{record && record[c]}</th>
                        })
                       
                      );
                    }) }
                    <th>{parseFloat(averageEveningSBPM).toFixed(2)} </th>
                    <th> {parseFloat(averageEveningDBP).toFixed(2)}</th>
                    <th> {parseFloat(averageEveningPulse).toFixed(2)}</th>

                    <th>{parseFloat(averageMorningSBPM + averageEveningSBPM / 2).toFixed(2)} </th>
                    <th>{parseFloat(averageMorningDBP + averageEveningDBP / 2).toFixed(2)} </th>
                    <th>{parseFloat(averageMorningPulse + averageEveningPulse / 2).toFixed(2)} </th>
                 
        
        
      </tr>
    </>
  );
}
