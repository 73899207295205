import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { getPatientsAsync } from "../Redux/patientSlice";
import { getUsersAsync } from "../Redux/userSlice";
import DoctorList from "../Doctor/DoctorList";
import { Link } from "react-router-dom";

export default function AdminDashboard() {
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const patients = useSelector((state) => state.patientSlice.patients);
  const users = useSelector((state) => state.userSlice.users);
  const male = patients.filter((record) => record.gender === "Male");
  const female = patients.filter((record) => record.gender === "Female");

  // console.log("Doctors", users.length);

  let totalCount = 0;
  let members = 0;
  members = patients?.reduce(
    (partialSum, detail) =>
      partialSum + (parseInt(detail.family_members?.length) || 0),
    0
  );

  totalCount = parseInt(members) + parseInt(patients.length);

  useEffect(() => {
    // console.log("ssss");
    dispatch(
      getPatientsAsync({ userType: authData.panel, userId: authData.user.id })      
    );
    dispatch(getUsersAsync());
  }, [dispatch]);

  return (
    <div className="content-wrapper">
      <div className="container-full">
        <section className="content">
          <div className="row">
            {/* <div class="col-md-4 col-12">
							<div class="box">
								<div class="box-body px-0 text-center">
									<div >
									<div class="resize-triggers"><div class="expand-trigger"><div ></div></div><div class="contract-trigger"></div></div></div>
									<div class="mt-15 d-inline-block">
										<div class="text-start mb-10">
											<span class="badge badge-xl badge-dot badge-primary me-15"></span> Woman 44%
										</div>
										<div class="text-start">
											<span class="badge badge-xl badge-dot badge-primary-light me-15"></span> Man 55%
										</div>
									</div>
								</div>
							</div>
						</div> */}

            <div className="col-xxxl-12 col-xl-12 col-12">
              <div className="box">
                <div className="box-body">
                  <div className="d-md-flex align-items-center text-md-start text-center">
                    <div className="me-md-30">
                      {/* <img src="assets/images/lgonew.png" alt="" style={{ height : 70 }} /> */}
                      <img
                        src="assets/images/lgonew.png"
                        alt=""
                        style={{ height: 120 }}
                      />
                    </div>
                    <div className="d-lg-flex w-p100 align-items-center justify-content-between">
                      <div className="me-lg-10 mb-lg-0 mb-10">
                        <h3 className="mb-0">
                          Welcome to Home BP Monitoring Study
                        </h3>
                        <p className="mb-0 fs-16">
                          {/* Consultoin of a
                            pulmonolgist, spirogrphy, cardiogram */}
                        </p>
                      </div>
                      <div>
                        <a
                          href="#"
                          className="waves-effect waves-light btn btn-primary text-nowrap"
                        >
                          Download App
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-12" onClick={() => {}}>
                  <div className="box">
                    <div className="box-body">
                      <div className="d-flex align-items-center">
                        <div className="me-15">
                          <img
                            src="https://medical-admin-template.multipurposethemes.com/images/svg-icon/color-svg/custom-18.svg"
                            alt=""
                            className="w-120"
                          />
                        </div>
                        <div>
                          <h4 className="mb-0">
                            <Link to="/view-cases?r_type=all">Total Cases</Link>
                          </h4>
                          <h5 className="mb-0">{totalCount}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-12">
                  <div className="box">
                    <div className="box-body">
                      <div className="d-flex align-items-center">
                        <div className="me-15">
                          <img
                            src="https://medical-admin-template.multipurposethemes.com/images/svg-icon/color-svg/custom-20.svg"
                            alt=""
                            className="w-120"
                          />
                        </div>
                        <div>
                          <h4 className="mb-0">
                            {" "}
                            <Link to="/view-cases?r_type=patient">
                              Total Patients
                            </Link>
                          </h4>
                          <h5 className="mb-0">
                            {patients.length ? patients.length : "0"} ( M-{" "}
                            {male.length} | F-{female.length} )
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="box">
                    <div className="box-body">
                      <div className="d-flex align-items-center">
                        <div className="me-15">
                          <img
                            src="https://medical-admin-template.multipurposethemes.com/images/svg-icon/color-svg/custom-20.svg"
                            alt=""
                            className="w-120"
                          />
                        </div>
                        <div>
                          <h4 className="mb-0">
                            <Link to="/view-cases?r_type=family">
                              Total Family Members
                            </Link>
                          </h4>
                          <h5 className="mb-0">{members}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-12">
                  <div className="box">
                    <div className="box-body">
                      <div className="d-flex align-items-center">
                        <div className="me-15">
                          <img
                            src="https://medical-admin-template.multipurposethemes.com/images/svg-icon/color-svg/custom-19.svg"
                            alt=""
                            className="w-120"
                          />
                        </div>
                        <div>
                          <Link to="/view-doctors"> <h4 className="mb-0">Total Doctors</h4></Link>
                         
                          <h5 className="mb-0">
                            {users.length ? users.length : "0"}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="row">
                <div className="col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Doctor List</h4>
                      <div className="box-controls pull-right">
                        <div className="lookup lookup-circle lookup-right">
                          <input type="text" name="s" />
                        </div>
                      </div>
                    </div>
                    <div className="box-body no-padding"> 
                      <DoctorList/>
                    </div>
                    
                  </div>
                </div>
                </div> */}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
