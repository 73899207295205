import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { logout } from "../../Redux/authSlice";
import EventBus from "../../middleware/eventBus";

import AdminMenu from "./AdminMenu";
import DoctorMenu from "./DoctorMenu";
import PatientMenu from "./PatientMenu";

export default function Header() {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  // console.log("User Type", authData.panel);
  // console.log("User Data", authData.user.id);
  const logOut = useCallback(() => { 
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [authData.user, logOut]);

  if (!authData.isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <header className="main-header">
        <div className="d-flex align-items-center logo-box justify-content-start">
          <Link to="#" className="logo">
            {/* <div className="logo-mini w-50">
              <span className="light-logo">
                <img src="/assets/images/micro.png" alt="logo" />
              </span>
              <span className="dark-logo">
                <img src="/assets/images/micro.png" alt="logo" />
              </span>
            </div> */}
            <div className="logo-lg">
              <span className="light-logo">
              {/* <img src="/assets/images/micro.png" alt="logo" /> */}
                <img src="assets/images/lgonew.png" alt="" style={{ height : 70 }} />
              </span>
              {/* <span className="dark-logo">
                <img src="/assets/images/micro.png" alt="logo" />
              </span> */}
            </div>
          </Link>
        </div>

        <nav className="navbar navbar-static-top">
          <div className="app-menu">
            <ul className="header-megamenu nav">
              <li className="btn-group nav-item">
                <a
                  href="#"
                  className="waves-effect waves-light nav-link push-btn btn-primary-light"
                  data-toggle="push-menu"
                  role="button"
                >
                  <i className="icon-Menu">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </a>
              </li>
              <li className="btn-group d-lg-inline-flex d-none">
                <div className="app-menu">
                  <div className="search-bx mx-5">
                    <form>
                      <div className="input-group">
                        <input
                          type="search"
                          className="form-control"
                          placeholder="Search"
                        />
                        <div className="input-group-append">
                          <button
                            className="btn"
                            type="submit"
                            id="button-addon3"
                          >
                            <i className="icon-Search">
                              <span className="path1"></span>
                              <span className="path2"></span>
                            </i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="navbar-custom-menu r-side">
            <ul className="nav navbar-nav">
              <li className="dropdown user user-menu">
                <a
                  href="#"
                  className="waves-effect waves-light dropdown-toggle w-auto l-h-12 bg-transparent p-0 no-shadow"
                  data-bs-toggle="dropdown"
                  title="User"
                >
                  <div className="d-flex pt-1">
                    <div className="text-end me-10">
                      <p className="pt-5 fs-14 mb-0 fw-700 text-primary">
                        {authData.user.name}
                      </p>
                      <small className="fs-10 mb-0 text-uppercase text-mute">
                        {authData.user.user_type
                          ? authData.user.user_type.name
                          : "Patient"}
                      </small>
                    </div>
                    {/* <img
                      src="/assets/images/avatar.png"
                      className="avatar rounded-10 bg-primary-light h-40 w-40"
                      alt=""
                    /> */}
                  </div>
                </a>
                <ul className="dropdown-menu animated flipInX">
                  <li className="user-body">
                    {/* <Link to={`/patient-profile/`} className="dropdown-item">
                      <i className="ti-user text-muted me-2"></i> Profile
                    </Link> */}
                    <Link
                      className="dropdown-item"
                      to="/"
                      onClick={logOut}
                    >
                      <i className="ti-lock text-muted me-2"></i> Logout
                    </Link> 
                    {/* <Link to="/" onClick={logOut} >Sign Out</Link> */}
                  </li>
                </ul>
              </li>
              {/* <li className="btn-group nav-item d-lg-inline-flex d-none">
                <a
                  href="#"
                  data-provide="fullscreen"
                  className="waves-effect waves-light nav-link full-screen btn-warning-light"
                  title="Full Screen"
                >
                  <i className="icon-Position"></i>
                </a>
              </li> */}
            </ul>
          </div>
        </nav>
      </header>
      {/* {
        m1 && <DoctorMenu/>
      } */}
      {/* Call Menu */}
      {authData.panel === "Doctor" ? <DoctorMenu /> : <></>}
      {authData.panel === "Admin" ? <AdminMenu /> : <></>}
      {authData.panel === "Patient" ? <PatientMenu /> : <></>}
    </div>
  );
}
