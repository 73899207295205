import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baseApi from "../Http/baseApi";
import { toast } from "react-toastify";
// import { updateUserTypeAsync } from "./masterSlice";
 



export const getUsersAsync = createAsyncThunk(
  "users/getUsersAsync",
  async () => {
    const response = await baseApi.get(`users`);
    return response.data;
  }
);

export const getDoctorByIdAsync = createAsyncThunk(
  "users/getDoctorByIdAsync",
  async (payload) => {    
    const response = await baseApi.get(`users/${payload.id}`);    
    return response.data;
  }
); 
export const addUserAsync = createAsyncThunk(
  "users/addUsersAsync",
  async (payload) => {
   
   try{
    const response = await baseApi.post("users", payload);  
    toast.success("Registration Successful!"); 
   }
   catch(err){
     console.log("error",err.response)
     if(err.response.status === 409){
      console.log("exist");
      toast.error(err.response.data.message); 
     }
   }
    
  }
);

export const updateUserAsync = createAsyncThunk(
  "users/updateUserAsync",
  async (payload) => {
    const response = await baseApi.put(
      `users/${payload.id}`,
      payload
    ); 
    return response.data;
  }
);
export const deleteUserAsync = createAsyncThunk(
  "users/deleteUserAsync",
  async (payload) => {
    await baseApi.delete(`users/${payload.id}`); 
    return payload;
  }
);

export const sendCreds = createAsyncThunk(
  "users/sendCredsAsync",
  async (payload) => {  
    try{
      const response = await baseApi.post("sendpassword", payload);   
       if(response.status===200){
          toast.success("Your password has been sent to your registered Email and Phone!"); 
        }      
     }
     catch(err){
       console.log("error",err.response)
       if(err.response.status === 404){ 
        toast.error(err.response.data.message); 
       }
     }
 
  }
); 

const initialState = {
  users: [],
  user: [],
  errorMessage: "",
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: {
    [getUsersAsync.fulfilled]: (state, action) => { 
      return { ...state, users: action.payload };
    },
    [getDoctorByIdAsync.fulfilled]: (state, action) => {      
      return { ...state, user: action.payload };
    },
    [addUserAsync.fulfilled]: (state, action) => {
      state.users.push(action.payload);
      // return {...state, users: action.payload} ;
    },

    // [sendCreds.fulfilled]: (state, action) => {      
    //   return { ...state, user: action.payload };
    // },


    [updateUserAsync.fulfilled]: (state, action) => {
      const index = state.users.findIndex(
        (user) => user.id === action.payload.id
      );
      state.users[index] = action.payload;
      // return {...state, users: action.payload} ;
    },
    [updateUserAsync.rejected]: (state) => {
      console.log("Error occured");
      return { ...state, errorMessage: "Something went wrong" };
    },
    [deleteUserAsync.fulfilled]: (state, action) => {
      let users = state.users.filter(
        (user) => user.id !== action.payload.id
      );

      return { ...state, users: users };
    },
  },
});

export default userSlice.reducer;
